function _T(text){
    const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
    const translations = {
        "Tawkeel application partners with a wide network of accredited offices and external agents across the world to showcase our Umrah services to Muslims around the world.": {
            "en": "Tawkeel application partners with a wide network of accredited offices and external agents across the world to showcase our Umrah services to Muslims around the world.",
            "ar": "تعمل شركة توكيل بالشراكة مع شبكة واسعة من المكاتب المعتمدة والوكلاء الخارجيين في عدة دول حول العالم، بهدف شرح ونشر خدماتها للمسلمين في جميع أنحاء العالم بما يخص عمرة البدل",
            "ur": ""
        },
        "Agents": {
            "en": "Agents",
            "ar": "وكلاء",
            "ur": ""
        },
        "Find Agent": {
            "en": "Find Agent",
            "ar": "ابحث عن الوكيل",
            "ur": ""
        },
        "SIGN IN": {
            "en": "SIGN IN",
            "ar": "تسجيل دخول",
            "ur": ""
        },
        "SIGN UP": {
            "en": "SIGN UP",
            "ar": "تسجيل جديد",
            "ur": ""
        },
        "Egypt": {
            "en": "Egypt",
            "ar": "مصر",
            "ur": ""
        },
        "Egypt": {
            "en": "Egypt",
            "ar": "مصر",
            "ur": ""
        },
        "Cairo": {
            "en": "Cairo",
            "ar": "القاهرة",
            "ur": ""
        },
        "Malaysia": {
            "en": "Malaysia",
            "ar": "ماليزيا",
            "ur": ""
        },
        "Kuala Lumpur": {
            "en": "Kuala Lumpur",
            "ar": "كوالا لمبور",
            "ur": ""
        },
        "Indonesia": {
            "en": "Indonesia",
            "ar": "أندونيسيا",
            "ur": ""
        },
        "Jakarta": {
            "en": "Jakarta",
            "ar": "جاكرتا",
            "ur": ""
        },
        "Philippines": {
            "en": "Philippines",
            "ar": "الفلبين",
            "ur": ""
        },
        "Manila": {
            "en": "Manila",
            "ar": "مانيلا",
            "ur": ""
        },
        "Turkey": {
            "en": "Turkey",
            "ar": "تركيا",
            "ur": ""
        },
        "Ankara": {
            "en": "Ankara",
            "ar": "أنقرة",
            "ur": ""
        },
        "Rwanda": {
            "en": "Rwanda",
            "ar": "رواندا",
            "ur": ""
        },
        "Kigali": {
            "en": "Kigali",
            "ar": "كيغالي",
            "ur": ""
        },
        "Netherlands": {
            "en": "Netherlands",
            "ar": "هولندا",
            "ur": ""
        },
        "Amsterdam": {
            "en": "Amsterdam",
            "ar": "أمستردام",
            "ur": ""
        },
        "France": {
            "en": "France",
            "ar": "فرنسا",
            "ur": ""
        },
        "Paris": {
            "en": "Paris",
            "ar": "باريس",
            "ur": ""
        },
        "Spain": {
            "en": "Spain",
            "ar": "إسبانيا",
            "ur": ""
        },
        "Madrid": {
            "en": "Madrid",
            "ar": "مدريد",
            "ur": ""
        },
        "England": {
            "en": "England",
            "ar": "انجلترا",
            "ur": ""
        },
        "London": {
            "en": "London",
            "ar": "لندن",
            "ur": ""
        },
        "Singapore": {
            "en": "Singapore",
            "ar": "سنغافورة",
            "ur": ""
        },
        "Tunisia": {
            "en": "Tunisia",
            "ar": "تونس",
            "ur": ""
        },
        "Tunis": {
            "en": "Tunis",
            "ar": "تونس",
            "ur": ""
        },
        "Morocco": {
            "en": "Morocco",
            "ar": "المغرب",
            "ur": ""
        },
        "Rabat": {
            "en": "Rabat",
            "ar": "الرباط",
            "ur": ""
        },
        "India": {
            "en": "India",
            "ar": "الهند",
            "ur": ""
        },
        "New Delhi": {
            "en": "New Delhi",
            "ar": "نيودلهي",
            "ur": ""
        },
        "Pakistan": {
            "en": "Pakistan",
            "ar": "باكستان",
            "ur": ""
        },
        "Islamabad": {
            "en": "Islamabad",
            "ar": "اسلام اباد",
            "ur": ""
        },
        "Britain": {
            "en": "Britain",
            "ar": "بريطانيا",
            "ur": ""
        },
        "America": {
            "en": "America",
            "ar": "أمريكا",
            "ur": ""
        },
        "Washington, D.C.": {
            "en": "Washington, D.C.",
            "ar": "واشنطن العاصمة",
            "ur": ""
        },
        "South Africa": {
            "en": "South Africa",
            "ar": "جنوب أفريقيا",
            "ur": ""
        },
        "Cape Town": {
            "en": "Cape Town",
            "ar": "كيب تاون",
            "ur": ""
        },
        "Lesotho": {
            "en": "Lesotho",
            "ar": "ليسوتو",
            "ur": ""
        },
        "Maseru": {
            "en": "Maseru",
            "ar": "ماسيرو",
            "ur": ""
        },
        "Eswatini": {
            "en": "Eswatini",
            "ar": "إسواتيني",
            "ur": ""
        },
        "Mbabane": {
            "en": "Mbabane",
            "ar": "مباباني",
            "ur": ""
        },
        "Botswana": {
            "en": "Botswana",
            "ar": "بوتسوانا",
            "ur": ""
        },
        "Gaborone": {
            "en": "Gaborone",
            "ar": "غابورون",
            "ur": ""
        },
        "Zimbabwe": {
            "en": "Zimbabwe",
            "ar": "زيمبابوي",
            "ur": ""
        },
        "Harare": {
            "en": "Harare",
            "ar": "هراري",
            "ur": ""
        },
        "Zambia": {
            "en": "Zambia",
            "ar": "زامبيا",
            "ur": ""
        },
        "Lusaka": {
            "en": "Lusaka",
            "ar": "لوساكا",
            "ur": ""
        },
        "Mozambique": {
            "en": "Mozambique",
            "ar": "موزمبيق",
            "ur": ""
        },
        "Maputo": {
            "en": "Maputo",
            "ar": "مابوتو",
            "ur": ""
        },
        "Namibia": {
            "en": "Namibia",
            "ar": "ناميبيا",
            "ur": ""
        },
        "Windhoek": {
            "en": "Windhoek",
            "ar": "ويندهوك",
            "ur": ""
        },
        "Angola": {
            "en": "Angola",
            "ar": "أنغولا",
            "ur": ""
        },
        "Luanda": {
            "en": "Luanda",
            "ar": "لواندا",
            "ur": ""
        },
        "Malawi": {
            "en": "Malawi",
            "ar": "ملاوي",
            "ur": ""
        },
        "Lilongwe": {
            "en": "Lilongwe",
            "ar": "ليلونغوي",
            "ur": ""
        },
        "Nigeria": {
            "en": "Nigeria",
            "ar": "نيجيريا",
            "ur": ""
        },
        "Abuja": {
            "en": "Abuja",
            "ar": "أبوجا",
            "ur": ""
        },
        "Mauritius": {
            "en": "Mauritius",
            "ar": "موريشيوس",
            "ur": ""
        },
        "Port Louis": {
            "en": "Port Louis",
            "ar": "بورت لويس",
            "ur": ""
        },
        "Réunion Island": {
            "en": "Réunion Island",
            "ar": "جزيرة ريونيون",
            "ur": ""
        },
        "Saint-Denis": {
            "en": "Saint-Denis",
            "ar": "سان دوني",
            "ur": ""
        },
        "Madagascar": {
            "en": "Madagascar",
            "ar": "مدغشقر",
            "ur": ""
        },
        "Antananarivo": {
            "en": "Antananarivo",
            "ar": "أنتاناناريفو",
            "ur": ""
        },
    }
    return translations[text] ? translations[text][language] : text; 
}
export default _T;