const order_countries = {
    "AF":"Afghanistan",
    "AX":"Aland Islands",
    "AL":"Albania",
    "DZ":"Algeria",
    "AS":"American Samoa",
    "AD":"Andorra",
    "AO":"Angola",
    "AI":"Anguilla",
    "AQ":"Antarctica",
    "AG":"Antigua and Barbuda",
    "AR":"Argentina",
    "AM":"Armenia",
    "AW":"Aruba",
    "AU":"Australia",
    "AT":"Austria",
    "AZ":"Azerbaijan",
    "BS":"Bahamas",
    "BH":"Bahrain",
    "BD":"Bangladesh",
    "BB":"Barbados",
    "BY":"Belarus",
    "BE":"Belgium",
    "BZ":"Belize",
    "BJ":"Benin",
    "BM":"Bermuda",
    "BT":"Bhutan",
    "BO":"Bolivia",
    "BA":"Bosnia and Herzegovina",
    "BW":"Botswana",
    "BR":"Brazil",
    "IO":"British Indian Ocean Territory",
    "BN":"Brunei Darussalam",
    "BG":"Bulgaria",
    "BF":"Burkina Faso",
    "BI":"Burundi",
    "KH":"Cambodia",
    "CM":"Cameroon",
    "CA":"Canada",
    "KY":"Cayman Islands",
    "CF":"Central African Republic",
    "TD":"Chad",
    "CL":"Chile",
    "CN":"China",
    "CX":"Christmas Island",
    "CC":"Cocos Islands",
    "CO":"Colombia",
    "KM":"Comoros",
    "CG":"Congo",
    "CD":"Congo",
    "CK":"Cook Islands",
    "CR":"Costa Rica",
    "CI":"Cote d'Ivoire",
    "HR":"Croatia",
    "CU":"Cuba",
    "CW":"Curacao",
    "CV":"Cape Verde",
    "CY":"Cyprus",
    "CZ":"Czech Republic",
    "DK":"Denmark",
    "DJ":"Djibouti",
    "DM":"Dominica",
    "DO":"Ecuador",
    "EG":"Egypt",
    "SV":"El Salvador",
    "GQ":"Equatorial Guinea",
    "ER":"Eritrea",
    "EE":"Estonia",
    "ET":"Ethiopia",
    "FK":"Falkland Islands",
    "FO":"Faroe Islands",
    "FJ":"Fiji",
    "FI":"Finland",
    "FR":"France",
    "GF":"French Guiana",
    "PF":"French Polynesia",
    "GA":"Gabon",
    "GM":"Gambia",
    "GE":"Georgia",
    "DE":"Germany",
    "GH":"Ghana",
    "GI":"Gibraltar",
    "GR":"Greece",
    "GL":"Greenland",
    "GD":"Grenada",
    "GP":"Guadeloupe",
    "GU":"Guam",
    "GT":"Guatemala",
    "GG":"Guernsey",
    "GN":"Guinea",
    "GW":"Guinea-Bissau",
    "GY":"Guyana",
    "HT":"Haiti",
    "VA":"Vatican City",
    "HN":"Honduras",
    "HK":"Hong Kong",
    "HU":"Hungary",
    "IS":"Iceland",
    "IN":"India",
    "ID":"Indonesia",
    "IR":"Iran",
    "IQ":"Iraq",
    "IE":"Ireland",
    "IM":"Isle of Man",
    "IT":"Italy",
    "JM":"Jamaica",
    "JP":"Japan",
    "JE":"Jersey",
    "JO":"Jordan",
    "KZ":"Kenya",
    "KI":"Kiribati",
    "XK":"Kosovo",
    "KW":"Kuwait",
    "KG":"Kyrgyzstan",
    "LA":"Laos",
    "LV":"Latvia",
    "LB":"Lebanon",
    "LS":"Lesotho",
    "LR":"Liberia",
    "LY":"Libyan Arab Jamahiriya",
    "LI":"Liechtenstein",
    "LT":"Lithuania",
    "LU":"Luxembourg",
    "MO":"Macao",
    "MK":"Macedonia",
    "MG":"Madagascar",
    "MW":"Malawi",
    "MY":"Malaysia",
    "MV":"Maldives",
    "ML":"Mali",
    "MT":"Malta",
    "MH":"Marshall Islands",
    "MQ":"Martinique",
    "MR":"Mauritania",
    "MU":"Mauritius",
    "YT":"Mayotte",
    "MX":"Mexico",
    "FM":"Micronesia",
    "MD":"Moldova",
    "MC":"Monaco",
    "MN":"Mongolia",
    "ME":"Montenegro",
    "MS":"Montserrat",
    "MA":"Morocco",
    "MZ":"Mozambique",
    "MM":"Myanmar",
    "NA":"Namibia",
    "NR":"Nauru",
    "NP":"Nepal",
    "NL":"Netherlands",
    "NC":"New Caledonia",
    "NZ":"New Zealand",
    "NI":"Nicaragua",
    "NE":"Niger",
    "NG":"Nigeria",
    "NU":"Niue",
    "NF":"Norfolk Island",
    "KP":"North Korea",
    "MP":"Northern Mariana Islands",
    "NO":"Norway",
    "OM":"Oman",
    "PK":"Pakistan",
    "PW":"Palau",
    "PS":"Palestine",
    "PA":"Panama",
    "PG":"Papua New Guinea",
    "PY":"Paraguay",
    "PE":"Peru",
    "PH":"Philippines",
    "PN":"Pitcairn",
    "PL":"Poland",
    "PT":"Portugal",
    "QA":"Qatar",
    "RO":"Romania",
    "RU":"Rwanda",
    "RE":"Reunion",
    "BL":"Saint Barthelemy",
    "SH":"Saint Helena",
    "KN":"Saint Kitts",
    "LC":"Saint Lucia",
    "MF":"Saint Martin",
    "PM":"Saint Pierre",
    "VC":"Saint Vincent",
    "WS":"Samoa",
    "SM":"San Marino",
    "ST":"Sao Tome and Principe",
    "SA":"Saudi Arabia",
    "SN":"Senegal",
    "RS":"Serbia",
    "SC":"Seychelles",
    "SL":"Sierra Leone",
    "SG":"Singapore",
    "SX":"Sint Maarten",
    "SK":"Slovakia",
    "SI":"Slovenia",
    "SB":"Solomon Islands",
    "SO":"Somalia",
    "ZA":"South Africa",
    "GS":"South Georgia",
    "KR":"South Korea",
    "SS":"South Sudan",
    "ES":"Spain",
    "LK":"Sri Lanka",
    "SD":"Sudan",
    "SR":"Suriname",
    "SJ":"Svalbard and Jan Mayen",
    "SZ":"Swaziland",
    "SE":"Sweden",
    "CH":"Switzerland",
    "SY":"Syrian Arab Republic",
    "TW":"Taiwan",
    "TJ":"Tajikistan",
    "TZ":"Tanzania",
    "TH":"Thailand",
    "TL":"Timor-Leste",
    "TG":"Togo",
    "TK":"Tokelau",
    "TO":"Tonga",
    "TT":"Trinidad and Tobago",
    "TN":"Tunisia",
    "TR":"Turkey",
    "TM":"Turkmenistan",
    "TC":"Turks and Caicos Islands",
    "TV":"Tuvalu",
    "UG":"Uganda",
    "UA":"Ukraine",
    "AE":"United Arab Emirates",
    "GB":"United Kingdom",
    "US":"United States",
    "UY":"Uruguay",
    "UZ":"Uzbekistan",
    "VU":"Vanuatu",
    "VE":"Venezuela",
    "VN":"Vietnam",
    "VG":"Virgin Islands, British",
    "VI":"Virgin Islands, U.S.",
    "WF":"Wallis and Futuna",
    "YE":"Yemen",
    "ZM":"Zambia",
    "ZW":"Zimbabwe",
}
export { order_countries };