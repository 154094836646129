import React, { useState } from 'react'
import googleStore from '../../assets/images/google-play-download.png'
import appleStore from '../../assets/images/apple-store-download.png'
import appGallery from '../../assets/images/Appgallery.png'
import heroScreenshot from '../../assets/images/help-center-image.png'
import mainLogo from "../../assets/images/main-logo.png"
import heroImage from "../../assets/images/screenshots/screenshot.png"
import heroVideo from "../../assets/videos/Hero-video-tawkeel.mp4"
import _T from '../../locale/Home/translator';
import { sendTwitterConversion } from '../../common/PixelEvents';

function play_video() {
    let video_container = document.getElementsByClassName("video-wrapper")[0];
    video_container.classList.add("show")
    let video = document.getElementById("tawkeel_intro");
    document.getElementsByTagName("body")[0].classList.add("fixed");
    video.play();
}
function stop_video() {
    let video_container = document.getElementsByClassName("video-wrapper")[0];
    video_container.classList.remove("show")
    let video = document.getElementById("tawkeel_intro");
    document.getElementsByTagName("body")[0].classList.remove("fixed");
    video.pause();
    video.currentTime = 0;
}

function Hero({ language }) {
    const [videoLoaded, setVideoLoaded] = useState(false);

    const handleVideoLoad = () => {
        setVideoLoaded(true);
    };
    function section_scroll(e, id) {
        e.preventDefault();
        let scrollDiv = document.getElementById(id).offsetTop;
        window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
    }
    return (
        <section id='home'>
            <div
                className={`image-fallback ${videoLoaded ? 'hidden' : ''}`}
                style={{ backgroundImage: `url(${heroImage})` }}
            ></div>
            <video
                className={`video-background ${videoLoaded ? '' : 'hidden'}`}
                onCanPlayThrough={handleVideoLoad}
                autoPlay
                loop
                muted
                playsInline
            >
                <source src={"https://tawkeelprod.blob.core.windows.net/upload-image/Hero-video-tawkeel.mp4"} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="home-overlay">
            </div>
            <div className='wrapper home-wrapper'>
                <div className='section-left'>
                    <img src={mainLogo} alt="Tawkeel" />
                    <h1 className='primary-heading ibm-plex-sans-arabic-bold'>{_T("Tawkeel")}</h1>
                    <p className='sub-heading'>{_T("For Umrah Al-Badal")}</p>
                    <a href="#" className='btn btn-primary-home mb-3' onClick={(e) => { section_scroll(e, 'about') }}>{_T("For more details")}</a>
                    <div className='btn-group'>
                        <a onClick={() => { sendTwitterConversion() }} href='https://tawkeel.onelink.me/u5qw/eea6bb58' rel='noreferrer' target="_blank" className='btn img-btn hover-up'>
                            <img src={googleStore} alt="GooglePlay Store" />
                        </a>
                        <a onClick={() => { sendTwitterConversion() }} href='https://tawkeel.onelink.me/u5qw/eea6bb58' rel='noreferrer' target="_blank" className='btn img-btn hover-up'>
                            <img src={appleStore} alt="Apple Store" />
                        </a>
                        {/* <a onClick={()=>{ sendTwitterConversion() }} href='https://apps.apple.com/us/app/tawkeel-%D8%AA%D9%88%D9%83%D9%8A%D9%84/id1583074584' rel='noreferrer' target="_blank" className='btn img-btn hover-up'>
                            <img src={appGallery} alt="App Gallery" />
                        </a> */}
                    </div>
                    {/* <div className='play-button'>
                        <button onClick={play_video}><span></span><i className="ri-play-fill"></i></button>
                    </div> */}
                </div>
                <div className='section-right'>
                    {/* <img src={heroScreenshot} alt="Tawkeel App Home Screen" className="hero-screenshot" /> */}
                </div>
            </div>
            <div className='hero-video'>
                <div className='video-wrapper'>
                    <button onClick={stop_video} className='btn-close'><i className="ri-close-line"></i></button>
                    <div className='video'>
                        <video id="tawkeel_intro" src='https://tawkeelprod.blob.core.windows.net/webvideos/tawkeel-intro-video.mp4' controls>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;