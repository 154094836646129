function _T(text){
    const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
    const translations = {
        "Voucher Details": {
            "en": "Voucher Details",
            "ar": "تفاصيل القسيمة",
            "ur": ""
        },
        "Name": {
            "en": "Name",
            "ar": "اﻻﺳﻢ",
            "ur": ""
        },
        "Name is required.": {
            "en": "Name is required.",
            "ar": "الاسم مطلوب.",
            "ur": ""
        },
        "Email": {
            "en": "Email",
            "ar": "اﻟبر ﻳﺪ اﻹﻟﻜتروني",
            "ur": ""
        },
        "Please enter a valid email address.": {
            "en": "Please enter a valid email address.",
            "ar": "يرجى إدخال عنوان بريد إلكتروني صالح.",
            "ur": ""
        },
        "Email address already exists": {
            "en": "Email address already exists",
            "ar": "عنوان البريد الإلكتروني موجود بالفعل.",
            "ur": ""
        },
        "Phone": {
            "en": "Phone",
            "ar": "رﻗﻢ اﻟﻬﺎﺗﻒ",
            "ur": ""
        },
        "Please enter a valid phone number.": {
            "en": "Please enter a valid phone number.",
            "ar": "يرجى إدخال رقم هاتف صالح.",
            "ur": ""
        },
        "Mobile number already exists": {
            "en": "Mobile number already exists",
            "ar": "رقم الهاتف المحمول موجود بالفعل.",
            "ur": ""
        },
        "Continue": {
            "en": "Continue",
            "ar": "اﻟﺘﺎلي",
            "ur": ""
        },
        "Please Wait...": {
            "en": "Please Wait...",
            "ar": "يرجى الانتظار...",
            "ur": ""
        },
        "Voucher": {
            "en": "Voucher",
            "ar": "قسيمة",
            "ur": ""
        },
        "You got this voucher": {
            "en": "You got this voucher",
            "ar": "لقد حصلت على هذه القسيمة",
            "ur": ""
        },
        "Something went wrong. Please try again!": {
            "en": "Something went wrong. Please try again!",
            "ar": "حدث خطأ ما. يرجى المحاولة مرة أخرى!",
            "ur": ""
        },
        "Confirm":{
            "en": "Confirm",
            "ar": "تأكيد",
            "ur": ""
        },
        "Registration":{
            "en": "Registration",
            "ar": "اﻟﺘﺴﺠﻴﻞ",
            "ur": ""
        },
        "Thank you":{
            "en": "Thank you",
            "ar": "ﺷـــﻜﺮًا ﻟﻜﻢ",
            "ur": ""
        },
        "Home Page":{
            "en": "Home Page",
            "ar": "ﺻﻔﺤﺔ اﻟﺒﺪاﻳﺔ",
            "ur": ""
        },
        "Passage Of Goodness":{
            "en": "Passage Of Goodness",
            "ar": "ﺑﻮاﺑﺔ اﻟﺨير ﻟﺘﻮﻛﻴﻞ اﻟﻐير",
            "ur": ""
        },
        "Download application":{
            "en": "Download application",
            "ar": "ﺣﻤّﻞ اﻟﺘﻄﺒﻴﻖ",
            "ur": ""
        },
        "You will receive an email with the free Umrah code and a link to download the app":{
            "en": "You will receive an email with the free Umrah code and a link to download the app",
            "ar": "ﺳﺘﺼﻠﻚ رﺳﺎﻟﺔ ﻋﻠﻰ ﺑﺮ ﻳﺪك اﻹﻟﻜتروني ﺗﺤﺘﻮي ﻋﻠﻰ رﻣﺰ اﻟﻌﻤﺮة المﺠﺎني وراﺑﻂ ﺗﺤﻤﻴﻞ اﻟﺘﻄﺒﻴﻖ",
            "ur": ""
        },
        "You've received a free code to order Umrah Al-Badal":{
            "en": "You've received a free code to order Umrah Al-Badal",
            "ar": "ﻟﻘﺪ ﺣﺼﻠﺖ ﻋﻠﻰ رﻣﺰ ﻣﺠﺎني ﻟـﻄﻠــــﺐ ﻋُـــــــــــﻤﺮة اﻟﺒــــــــــــــــﺪل",
            "ur": ""
        }
    }
    return translations[text] ? translations[text][language] : text; 
}
export default _T;