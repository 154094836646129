import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";
import { Link, useNavigate, useParams } from 'react-router-dom';
// import images
import Navbar from '../../components/NavbarInner';
import Footer from '../../components/FooterInner';
import mainLogo from "../../assets/images/login-logo.png"
import { languages } from '../../common/languages'
import { resetPassword } from '../../helper/api-methods';
import _T from '../../locale/AgencyPortal/translator';


function ResetPassword({ language }) {
    let params = useParams();
    const token = params.token;
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [passwordReset, setPasswordReset] = useState(false);
    const [errors, setErrors] = useState({ password: "", confirmPassword: "", apiError: "" });
    const history = useNavigate();

    let direction = languages[language].direction
    if (direction === "RTL") {
        document.getElementsByTagName("body")[0].classList.add("RTL");
    } else {
        document.getElementsByTagName("body")[0].classList.remove("RTL");
    }

    const validateSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const error = { password: "", confirmPassword: "", apiError: "" }
        let flag = false;
        if (password !== confirmPassword) {
            error.confirmPassword = _T("Passwords do not match.");
            flag = true
        }
        if (!password || password.replaceAll(/\s/g, '').length < 6) {
            error.password = _T("Please provide a valid password");
            flag = true
        }
        if (!confirmPassword || confirmPassword.replaceAll(/\s/g, '').length < 6) {
            error.confirmPassword = _T("Please provide a valid password");
            flag = true
        }
        setErrors(error);
        if (!flag) {
            resetPassword("/reset-password", { token: token, password: password, isWebPortal: true }).then((data) => {
                if (data.status) {
                    // history('/login');
                    setPasswordReset(true)
                }
                else {
                    const error = { password: "", confirmPassword: "", apiError: data.message }
                    setErrors(error);
                    resetForm();
                    setLoading(false)
                }
            }).catch((e) => {
                const error = { password: "", confirmPassword: "", apiError: e }
                setErrors(error);
                resetForm();
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }

    const resetForm = () => {
        setPassword("");
        setConfirmPassword("");
    }


    return (
        <>
            <Navbar language={languages[language]} />
            <section id='agentAgencyHero'>
                <div className='wrapper agentAgencyHero-wrapper'>
                    <div className='section-left'>
                        <img src={mainLogo} alt="Tawkeel" />
                        <h1 className='secondary-heading' style={{ color: "#000" }}>{"Reset Password"}</h1>
                        {errors.apiError ? <Alert color="danger">{_T(errors.apiError)}</Alert> : null}
                        <div className="p-2 mt-5">
                            <form className="form-horizontal" onSubmit={validateSubmit}>
                                {
                                    !passwordReset ?
                                        <>
                                            <div className="auth-form-group-custom mb-4">
                                                <i className="ri-lock-fill auti-custom-input-icon"></i>
                                                {/* <Label htmlFor="userpassword">New Password</Label> */}
                                                <Input name="password" value={password} onChange={(e) => { setPassword(e.target.value) }} type="password" className={`form-control ${errors.password ? "is-invalid" : ""}`} id="userpassword" placeholder="Enter New Password" />
                                                {errors.password ? <div className="invalid-feedback">{errors.password}</div> : null}
                                            </div>
                                            <div className="auth-form-group-custom mb-4">
                                                <i className="ri-lock-fill auti-custom-input-icon"></i>
                                                {/* <Label htmlFor="confirmPassword">Confirm Password</Label> */}
                                                <Input name="confirmPassword" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} type="password" className={`form-control ${errors.confirmPassword ? "is-invalid" : ""}`} id="confirmPassword" placeholder="Re-Enter New Password" />
                                                {errors.confirmPassword ? <div className="invalid-feedback">{errors.confirmPassword}</div> : null}
                                            </div>
                                            <div className="mt-4 text-center">
                                                <button disabled={loading} className="btn-login" type="submit">{loading ? "Please Wait" : "Reset"}</button>
                                            </div>
                                        </> : <><h2 className='mt-0' style={{color: "#C99D55"}}>Password has been reset successfully!</h2></>
                                }
                                <div className="mt-4 text-center">
                                    <Link to="/login" className="text-muted login-links"><i className="mdi mdi-lock me-1"></i>{_T("Back to login")}</Link>
                                </div>
                            </form>
                        </div>
                        <div className='btn-group hero-btn-group'>

                        </div>
                    </div>
                    <div className='section-right'>
                        {/* <img src={helpCenterHero} alt="Privacy Policy"/> */}
                    </div>
                </div>
            </section>
            <Footer language={languages[language]} />
        </>
    );
}

export default ResetPassword