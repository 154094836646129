import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import defaultUser from "../../assets/images/users/avatar.jpg"
import { seekerAgencyDetailsWeb } from '../../helper/backend-methods';
import { showToast } from "../../components/Toast";
import _T from '../../locale/AgencyPortal/translator';


const MyProfile = () => {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        seekerAgencyDetailsWeb().then(({ data }) => {
            if (data.status) {
                setUser(data.data && data.data.seeker_agency ? data.data.seeker_agency : {})
            }
            else {
              showToast("error", _T("Request Failed"), data.message)
            }
            setLoading(false)
          }).catch((err) => {
            showToast("error", _T("Request Failed"), err.message)
            setLoading(false)
          });
    }, [])
    return (
        <div className='profile-container'>
            <div className="profile-picture d-flex justify-center align-items-center">
                {
                    loading ? <Skeleton height={150} width={150} borderRadius={100} />
                        : <img src={user.agency_logo_url ? user.agency_logo_url : defaultUser} className="" onError={(e) => { e.target.onerror = null; e.target.src = defaultUser }} alt='User' />
                }
            </div>
            <div className="profile-info-container">
                <div className="info-row">
                    <div className="info-col text-center">
                        <p className="text-truncate fs-18 mb-2 text-dark">{_T("Company Name")}</p>
                        {
                            loading ? <Skeleton height={22} width={35} /> :
                                <h4 className="mb-0 mt-2">{user.agency_name ? user.agency_name : "N/A"}</h4>
                        }
                    </div>
                    <div className="info-col text-center">
                        <p className="text-truncate fs-18 mb-2 text-dark">{_T("Agent's Country")}</p>
                        {
                            loading ? <Skeleton height={22} width={35} /> :
                                <h4 className="mb-0 mt-2">{user.country ? user.country : "N/A"}</h4>
                        }
                    </div>
                    <div className="info-col text-center">
                        <p className="text-truncate fs-18 mb-2 text-dark">{_T("Agent's Email")}</p>
                        {
                            loading ? <Skeleton height={22} width={35} /> :
                                <h4 className="mb-0 mt-2">{user.agent_email ? user.agent_email : "N/A"}</h4>
                        }
                    </div>
                    <div className="info-col text-center">
                        <p className="text-truncate fs-18 mb-2 text-dark">{_T("Agent's Phone Number")}</p>
                        {
                            loading ? <Skeleton height={22} width={35} /> :
                                <h4 className="mb-0 mt-2">{user.agency_phone_number ? user.agency_phone_number : "N/A"}</h4>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyProfile
