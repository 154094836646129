const getUserInfo = () =>{
    let userObject = localStorage.getItem("user");
    try {
        userObject = JSON.parse(userObject);
        return userObject;
    } catch (error) {
        return false;
    }
}
const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export const formatAPIDate = (apiDate) => {
    // If there is no dot in the date string, append ".000Z"
    if (!apiDate.includes('.')) {
        return apiDate + ".000Z";
    }
    // If there is a dot but no 'Z', append 'Z'
    if (!apiDate.includes('Z')) {
        return apiDate + "Z";
    }
    else {
        // If the date string already has both dot and 'Z', leave it as is
        return apiDate;
    }
}

export const UTCtoLocalDate = (UTCDate, Formatted = true) => {
    if (!UTCDate) {
        return
    }
    if (Formatted) {
        const utcDateString = formatAPIDate(UTCDate);
        const utcDate = new Date(utcDateString);
        const localDateString = utcDate.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        }); // Converts to local time
        return localDateString
    }
    else {
        // for edit form
        const utcDateTime = new Date(UTCDate);
        const localDateTime = new Date(utcDateTime.getTime() - utcDateTime.getTimezoneOffset() * 60000);
        const year = localDateTime.getFullYear();
        const month = String(localDateTime.getMonth() + 1).padStart(2, '0');
        const day = String(localDateTime.getDate()).padStart(2, '0');
        const hours = String(localDateTime.getHours()).padStart(2, '0');
        const minutes = String(localDateTime.getMinutes()).padStart(2, '0');
        const localTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;
        return localTimeString;
    }
}

export const converToUTC = (date) => {
    const dateTimeObject = new Date(date);
    if (isNaN(dateTimeObject.getTime())) {
        console.log("Invalid Date");
        return;
    }
    const utcDateTimeString = dateTimeObject.toISOString();
    return utcDateTimeString
}
export const provideDatesInRange = (start, end) => {
    const dateArray = [];
    let currentDate = new Date(start);
    let endDate = new Date(end);
    while (currentDate <= endDate) {
        dateArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
}

export const SaudiTimeFormat = (date) => {
    try {
        if(date && new Date(date)){
            const dateTimeObject = new Date(date);
            const formattedTime = dateTimeObject.toLocaleString('en-US', {
                timeZone: 'Asia/Riyadh', // Time zone for Saudi Arabia
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            });
            return formattedTime
        }
        else{
            return 'Jan 00, 0000'
        }
    } catch (error) {
        return 'Jan 00, 0000'
    }
}

export {getUserInfo,formatBytes};