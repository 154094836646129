import { useEffect } from "react";
import { languages } from "../../common/languages";
import Footer from "../../components/FooterInner";
import Navbar from "../../components/NavbarInner";
import Hero from "./Hero";
import egypt from "../../assets/images/flags/egypt.png";
import malaysia from "../../assets/images/flags/malaysia.png";
import indonesia from "../../assets/images/flags/indonesia.png";
import philippines from "../../assets/images/flags/philippines.png";
import turkey from "../../assets/images/flags/turkey.png";
import rwanda from "../../assets/images/flags/rwanda.png";
import netherlands from "../../assets/images/flags/netherlands.png";
import france from "../../assets/images/flags/france.png";
import spain from "../../assets/images/flags/spain.png";
import england from "../../assets/images/flags/england.png";
import singapore from "../../assets/images/flags/singapore.png";
import pakistan from "../../assets/images/flags/pakistan.png";
import tunisia from "../../assets/images/flags/tunisia.png";
import morocco from "../../assets/images/flags/morocco.png";
import america from "../../assets/images/flags/america.png";
import india from "../../assets/images/flags/india.png";
import britain from "../../assets/images/flags/britain.png";

import southAfrice from "../../assets/images/flags/south-africa.png";
import lesotho from "../../assets/images/flags/lesotho.png";
import eswatini from "../../assets/images/flags/eswatini.png";
import botswana from "../../assets/images/flags/botswana.png";
import zimbabwe from "../../assets/images/flags/zimbabwe.png";
import zambia from "../../assets/images/flags/zambia.png";
import mozambique from "../../assets/images/flags/mozambique.png";
import namibia from "../../assets/images/flags/namibia.png";
import angola from "../../assets/images/flags/angola.png";
import malawi from "../../assets/images/flags/malawi.png";
import nigeria from "../../assets/images/flags/nigeria.png";
import mauritius from "../../assets/images/flags/mauritius.png";
import reunionIsland from "../../assets/images/flags/reunion-island.png";
import madagascar from "../../assets/images/flags/madagascar.png";
import "../../assets/css/agents.css";
import _T from "../../locale/Agents/translator";


function Agents({language}){
    useEffect(()=>{
        window.scrollTo({ top: 0});
        let direction = languages[language].direction
        if(direction === "RTL"){
          document.getElementsByTagName("body")[0].classList.add("RTL");
        }
        else{
          document.getElementsByTagName("body")[0].classList.remove("RTL");
        }
    },[])

    return(
        <>
        <Navbar language={languages[language]}/>
        <Hero/>
        <section id="agents">
            <div className="agents-wrapper wrapper">
                <div className="agent-list">
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={egypt} alt="Egypt Flag"/>
                            <h2 className="agent-name">{_T("Egypt")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Cairo")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={malaysia} alt="Malaysia Flag"/>
                            <h2 className="agent-name">{_T("Malaysia")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Kuala Lumpur")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={indonesia} alt="Indonesia Flag"/>
                            <h2 className="agent-name">{_T("Indonesia")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Jakarta")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={philippines} alt="Philippines Flag"/>
                            <h2 className="agent-name">{_T("Philippines")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Manila")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={turkey} alt="Turkey Flag"/>
                            <h2 className="agent-name">{_T("Turkey")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Ankara")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={rwanda} alt="Rwanda Flag"/>
                            <h2 className="agent-name">{_T("Rwanda")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Kigali")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={netherlands} alt="Netherlands Flag"/>
                            <h2 className="agent-name">{_T("Netherlands")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Amsterdam")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={france} alt="France Flag"/>
                            <h2 className="agent-name">{_T("France")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Paris")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={spain} alt="Spain Flag"/>
                            <h2 className="agent-name">{_T("Spain")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Madrid")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={england} alt="England Flag"/>
                            <h2 className="agent-name">{_T("England")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("London")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={singapore} alt="Singapore Flag"/>
                            <h2 className="agent-name">{_T("Singapore")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Singapore")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={tunisia} alt="Tunisia Flag"/>
                            <h2 className="agent-name">{_T("Tunisia")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Tunis")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={morocco} alt="Morocco Flag"/>
                            <h2 className="agent-name">{_T("Morocco")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Rabat")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={india} alt="India Flag"/>
                            <h2 className="agent-name">{_T("India")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("New Delhi")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={pakistan} alt="Pakistan Flag"/>
                            <h2 className="agent-name">{_T("Pakistan")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Islamabad")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={britain} alt="Britain Flag"/>
                            <h2 className="agent-name">{_T("Britain")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("London")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={america} alt="America Flag"/>
                            <h2 className="agent-name">{_T("America")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Washington, D.C.")}</span></h4>
                        </div>
                    </div>


                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={southAfrice} alt="South Africa Flag"/>
                            <h2 className="agent-name">{_T("South Africa")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Cape Town")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={lesotho} alt="Lesotho Flag"/>
                            <h2 className="agent-name">{_T("Lesotho")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Maseru")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={eswatini} alt="Eswatini Flag"/>
                            <h2 className="agent-name">{_T("Eswatini")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Mbabane")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={botswana} alt="Botswana Flag"/>
                            <h2 className="agent-name">{_T("Botswana")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Gaborone")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={zimbabwe} alt="Zimbabwe Flag"/>
                            <h2 className="agent-name">{_T("Zimbabwe")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Harare")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={zambia} alt="Zambia Flag"/>
                            <h2 className="agent-name">{_T("Zambia")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Lusaka")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={mozambique} alt="Mozambique Flag"/>
                            <h2 className="agent-name">{_T("Mozambique")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Maputo")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={namibia} alt="Namibia Flag"/>
                            <h2 className="agent-name">{_T("Namibia")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Windhoek")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={angola} alt="Angola Flag"/>
                            <h2 className="agent-name">{_T("Angola")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Luanda")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={malawi} alt="Malawi Flag"/>
                            <h2 className="agent-name">{_T("Malawi")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Lilongwe")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={nigeria} alt="Nigeria Flag"/>
                            <h2 className="agent-name">{_T("Nigeria")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Abuja")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={mauritius} alt="Mauritius Flag"/>
                            <h2 className="agent-name">{_T("Mauritius")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Port Louis")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={reunionIsland} alt="Réunion Island Flag"/>
                            <h2 className="agent-name">{_T("Réunion Island")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Saint-Denis")}</span></h4>
                        </div>
                    </div>
                    <div className="single-agent">
                        <div className="agent-header">
                            <img src={madagascar} alt="Madagascar Flag"/>
                            <h2 className="agent-name">{_T("Madagascar")}</h2>
                            <h4 className="agent-city-country"><span className="city">{_T("Antananarivo")}</span></h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer language={languages[language]}/>
        </>
    )
}
export default Agents;