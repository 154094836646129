import axios from "axios"
import { Navigate, useNavigate } from "react-router-dom";
const API_URL = process.env.REACT_APP_API_BASEURL;

var axiosApi = axios.create({
  baseURL: API_URL,
})
axiosApi.interceptors.response.use(function (response) {
  const statusCode = response.data.statusCode;
  if(statusCode === 1500){
    localStorage.removeItem("authToken")
    localStorage.removeItem("expiry")
    localStorage.removeItem("refresh")
    localStorage.removeItem("user")
    window.location = "/"
    return response;
  }
  else{
    return response;
  }
}, function (error) {
  return Promise.reject(error);
});

// Login Method
export const postLogin = (url, data) => {
  return axios.post(API_URL+"/admin"+url, data).then(response => {
      if (response.status === 400 || response.status === 500) {
        // eslint-disable-next-line no-throw-literal
          throw "Sorry! Something went wrong, please contact our support team";
      }
      if (!response.data.status) {
        // eslint-disable-next-line no-throw-literal
          throw "Please provide a valid email address and password";
      }
      return response.data;
  }).catch(err => {
    // eslint-disable-next-line no-throw-literal
      throw "Please provide a valid email address and password";
  });
}
export const forgotPassword = (url, data) => {
  return axios.post(API_URL+"/admin"+url, data).then(response => {
      if (!response.data.status) {
        // eslint-disable-next-line no-throw-literal
        throw "Sorry! Something went wrong, please contact our support team";
      }
      return response.data;
  }).catch(err => {
    // eslint-disable-next-line no-throw-literal
      throw "Sorry! Something went wrong, please contact our support team";
  });
}
export const resetPassword = (url, data) => {
  return axios.post(API_URL+"/admin"+url, data).then(response => {
      if (!response.data.status) {
        // eslint-disable-next-line no-throw-literal
        throw "Sorry! Something went wrong, please contact our support team";
      }
      return response.data;
  }).catch(err => {
    // eslint-disable-next-line no-throw-literal
      throw "Sorry! Something went wrong, please contact our support team";
  });
}
// Refresh Token Method
export const refreshToken = (url, data) => {
  return axios.post(API_URL+"/admin"+url, data).then(response => {
      return response.data;
  }).catch(err => {
      throw err;
  });
}

export async function apiWrapper(callback , url, data = {},config = {},locale = "/admin"){
  const accessToken = localStorage.getItem("authToken")
  if(accessToken && accessToken.length){
    return callback(locale+url,data,config, "Bearer "+accessToken)
  }
  else{

  }
}


export async function get(url, params = {}, config = {}, token) {
  axiosApi.defaults.headers.common["Authorization"] = await token;
  let p = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  return axiosApi.get(url+"?"+p, { ...config });
}

export async function post(url, data = {}, config = {}, token) {
  axiosApi.defaults.headers.common["Authorization"] = await token;
  return axiosApi.post(url, { ...data }, { ...config });
}

export async function postForm(url, formData, config = {}, token) {
  axiosApi.defaults.headers.common["Authorization"] = await token;
  let configuration = { headers: { 'content-type': 'multipart/form-data' } }
  return axiosApi.post(url, formData, configuration);
}

export async function put(url, data, config = {}, token) {
  axiosApi.defaults.headers.common["Authorization"] = await token;
  return axiosApi.put(url, { ...data }, { ...config });
}

export async function putForm(url, formData, config = {}, token) {
  axiosApi.defaults.headers.common["Authorization"] = await token;
  let configuration = { headers: { 'content-type': 'multipart/form-data' } }
  return axiosApi.put(url, formData, configuration);
}

export async function del(url, data = {}, config = {}, token) {
  axiosApi.defaults.headers.common["Authorization"] = await token;
  return await axiosApi.delete(url, { data : { ...data } , ...config });
}
