import React, { useEffect, useState } from "react"
import { Card, Row, Col } from "reactstrap";
import { showToast } from "../../components/Toast";
import { generateInvoice, getPackagesList, getServicesData } from "../../helper/backend-methods";
import paymentOptions from "../../assets/images/payment-options.png";
import _T from '../../locale/AgencyPortal/translator';

const PaymentPage = () => {
  const [apiLoading, setApiLoading] = useState(false)
  const [packages, setPackages] = useState([])
  const [unitPrice, setUnitPrice] = useState(0)
  const [quantity, setQuantity] = useState(0)
  const [customQuantity, setCustomQuantity] = useState(1)
  const [step, setStep] = useState(1)
  const [invoiceData, setInvoiceData] = useState(null)
  const [errors, setErrors] = useState({ quantity: "" })

  const fetchPackages = () => {
    getPackagesList().then(({ data }) => {
      if (data) {
        const sortedPackages = [...data.data.voucherPackagesView].sort((a, b) => a.package_quantity - b.package_quantity);
        setPackages(sortedPackages)
      }
      else {
        showToast("error", _T("Request Failed"), data.message)
      }
    }).catch((e) => {
      showToast("error", _T("Request Failed"), e.message)
    })
  }

  useEffect(() => {
    getServicesData().then(({ data }) => {
      if (data.status) {
        let service = data.data.find((s) => { return s.service_code === "umrahalbadal" })
        if (service && service.codes_unit_price) {
          setUnitPrice(service.codes_unit_price / 3.75);
        }
      }
    }).catch((err) => {
    });
  }, [])

  useEffect(() => {
    fetchPackages();
  }, [])

  const resetForm = () => {
    setQuantity(0)
    setCustomQuantity(1)
    setErrors({ quantity: "" })
    setStep(1);
    setApiLoading(false)
  }

  const validateSubmit = (e) => {
    e.preventDefault();
    let flag = false;
    const error = { quantity: "" };
    if (quantity === 0) {
      if (!customQuantity && customQuantity === 0) {
        error.quantity = "Please enter number of umrah packages";
        flag = true;
      }
    }
    setErrors(error)
    if (!flag) {
      setApiLoading(true);
      generateInvoice({ service_code: 'umrahalbadal', ...(quantity === 0 && { codes_quantity: customQuantity }), package_id: quantity === 0 ? null : quantity }).then(({ data }) => {
        if (data.status) {
          setInvoiceData(data.data)
          setStep(2);
        }
        else {
          showToast("error", _T("Request Failed"), data.message)
          setApiLoading(false);
        }
      }).catch((e) => {
        showToast("error", _T("Request Failed"), e.message)
        setApiLoading(false);
      })
    }
  }

  const handleQuantity = (value) => {
    let v = value.replace(/\D/g, '');
    if (v === "" || !v.length) {
      v = 0;
    }
    else {
      v = parseInt(value.replace(/\D/g, ''))
    }
    setCustomQuantity(v);
  }

  return (

    <div className="page-content">
      <div className="container-fluid">
        <Row>
          <div className="col-12">
            <Card>
              <div className="payment-form-container">
                {
                  step === 1 ?
                    <form autoComplete="off" onSubmit={validateSubmit}>
                      <Row className="mb-2 text-right">
                        <Col md={12} className="mb-4">
                          <label className="mb-0 fw-600" htmlFor="quantity">
                            {_T("Umrah Packages")} <span className="text-danger">*</span>
                          </label>
                          <p className="m-0">
                            {_T("To purchase Umrah Packages, please select the number of Umrah Packages you would like to purchase")},
                          </p>
                          {errors.quantity.length ? <div className="text-danger fs-12">{errors.quantity}</div> : <></>}
                        </Col>
                        <Col md={12} className="mb-5">
                          <div className="discount-code-quantity text-right">
                            {
                              packages.map((item, index) => {
                                return <label key={index} htmlFor={"package0" + index} className={item.id === quantity ? "selected" : ""}>
                                  <input type="radio" id={"package0" + index} name="quantity" value={50} checked={item.id === quantity} onChange={(e) => { if (e.target.checked) { setQuantity(item.id) } }} />
                                  {item.package_quantity}
                                </label>
                              })
                            }
                            {
                              unitPrice ?
                                <label key={"package00"} className={0 === quantity ? "selected" : ""}>
                                  <input type="radio" id={"package00"} name="quantity" value={0} checked={0 === quantity} onChange={(e) => { if (e.target.checked) { setQuantity(0) } }} />
                                  {_T("Custom")}
                                </label> : null
                            }
                            {
                              packages.length < 1 && unitPrice === 0 ?
                                <p className="d-flex align-items-center justify-center text-right"><i className="ri-error-warning-line text-danger fs-22 fw-300 me-2"></i>{_T("There are currently no umrah packages available.")}</p> : <></>
                            }
                          </div>
                        </Col>
                        <Col md={12} className="mb-3">
                          {
                            quantity === 0 && unitPrice !== 0 ?
                              <Row className="align-items-center">
                                <Col md={4} className="offset-md-3 text-right">
                                  <span className="fw-600 text-dark">{_T("Quantity")}</span>
                                </Col>
                                <Col md={2} className="offset-md-3 text-right">
                                  <input id="customQuantity" className="form-control text-right" value={customQuantity} onChange={(e) => { handleQuantity(e.target.value.slice(0, 4)) }} />
                                </Col>
                                <Col md={9} className="offset-md-3 text-right">
                                  <hr />
                                </Col>
                              </Row> : <></>
                          }
                          <Row className="mb-2">
                            <Col md={4} className="offset-md-3 text-right">
                              <span className="fw-600 text-dark">{_T("Sub-Total")}</span>
                            </Col>
                            {
                              quantity === 0 ?
                                <Col md={5} className="text-right">
                                  <span className="fw-600 text-dark">{new Intl.NumberFormat({ style: 'currency', currency: 'JPY' }).format((unitPrice * customQuantity).toFixed(2))} USD</span>
                                </Col> :
                                <Col md={5} className="text-right">
                                  <span className="mr-1 fw-600 text-dark">{packages.find((item) => item.id === quantity) ? new Intl.NumberFormat({ style: 'currency', currency: 'JPY' }).format(packages.find((item) => item.id === quantity).package_price.toFixed(2)) : 0}</span>
                                  <span className="fw-600 text-dark">{packages.find((item) => item.id === quantity) ? packages.find((item) => item.id === quantity).currency : ""}</span>
                                </Col>
                            }
                            <Col md={9} className="offset-md-3 text-right">
                              <hr />
                            </Col>
                            <Col md={4} className="offset-md-3 text-right">
                              <span className="fw-600 text-dark">{_T("VAT Applied")}</span>
                            </Col>
                            <Col md={5} className="text-right">
                              <span className="mr-1 fw-600">{_T("Calculated on checkout")}</span>
                            </Col>
                            <Col md={9} className="offset-md-3 text-right">
                              <hr />
                            </Col>
                            <Col md={4} className="offset-md-3 text-right">
                              <span className="fw-600 text-dark">{_T("Total")}</span>
                            </Col>
                            <Col md={5} className="text-right">
                              <span className="mr-1 fw-600">{_T("Calculated on checkout")}</span>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={12} className="text-right">
                          {/* <button className="mr-2 btn btn-outline-primary" type="button" onClick={() => resetForm()}>Cancel</button> */}
                          <button type="submit" className="btn btn-primary" disabled={apiLoading || (packages.length < 1 && unitPrice === 0)}>{apiLoading ? _T("Please Wait!") : _T("Proceed to Checkout")}</button>
                        </Col>
                      </Row>
                    </form> :
                    <form autoComplete="off" action={process.env.REACT_APP_PAYFORT_URL} method="post">
                      <input type="hidden" name="access_code" value={invoiceData.invoice.access_code} />
                      <input type="hidden" name="merchant_identifier" value={invoiceData.invoice.merchant_identifier} />
                      <input type="hidden" name="language" value={invoiceData.invoice.language} />
                      <input type="hidden" name="command" value="PURCHASE" />
                      <input type="hidden" name="merchant_reference" value={invoiceData.invoice.merchant_reference} />
                      <input type="hidden" name="signature" value={invoiceData.invoice.signature} />
                      <input type="hidden" name="amount" value={invoiceData.invoice.amount} />
                      <input type="hidden" name="currency" value={invoiceData.invoice.currency} />
                      <input type="hidden" name="customer_email" value={invoiceData.invoice.customer_email} />
                      <input type="hidden" name="merchant_extra" value={invoiceData.invoice.merchant_extra} />
                      <input type="hidden" name="return_url" value={invoiceData.invoice.return_url} />
                      <Row className="mb-2">
                        <Col md={4} className="offset-md-3 text-right">
                          <span className="fw-600 text-dark">{_T("Umrah Vouchers")}</span>
                        </Col>
                        <Col md={5} className="text-right">
                          <span className="mr-1 fw-600 text-dark">{invoiceData.invoice_view && invoiceData.invoice_view.voucher_quantity ? new Intl.NumberFormat({ style: 'currency', currency: 'JPY' }).format((invoiceData.invoice_view.voucher_quantity)) : 0}</span>
                        </Col>
                        <Col md={9} className="offset-md-3 text-right">
                          <hr />
                        </Col>
                        <Col md={4} className="offset-md-3 text-right">
                          <span className="fw-600 text-dark">{_T("Sub-Total")}</span>
                        </Col>
                        <Col md={5} className="text-right">
                          <span className="mr-1 fw-600 text-dark">{invoiceData.invoice_view && invoiceData.invoice_view.amount_without_vat ? new Intl.NumberFormat({ style: 'currency', currency: 'JPY' }).format((invoiceData.invoice_view.amount_without_vat).toFixed(2)) : 0}</span>
                          <span className="fw-600 text-dark">{invoiceData.invoice_view && invoiceData.invoice_view.currency ? invoiceData.invoice_view.currency : ""}</span>
                        </Col>
                        <Col md={9} className="offset-md-3 text-right">
                          <hr />
                        </Col>
                        <Col md={4} className="offset-md-3 text-right">
                          <span className="fw-600 text-dark">{_T("VAT Applied")} {invoiceData.invoice_view && invoiceData.invoice_view.vat_percentage ? "(" + invoiceData.invoice_view.vat_percentage + "%)" : ""}</span>
                        </Col>
                        <Col md={5} className="text-right">
                          <span className="mr-1 fw-600 text-dark">{invoiceData.invoice_view && invoiceData.invoice_view.vat ? new Intl.NumberFormat({ style: 'currency', currency: 'JPY' }).format((invoiceData.invoice_view.vat).toFixed(2)) : 0}</span>
                          <span className="fw-600 text-dark">{invoiceData.invoice_view && invoiceData.invoice_view.currency ? invoiceData.invoice_view.currency : ""}</span>
                        </Col>
                        <Col md={9} className="offset-md-3 text-right">
                          <hr />
                        </Col>
                        <Col md={4} className="offset-md-3 text-right">
                          <span className="fw-600 text-dark">{_T("Total")}</span>
                        </Col>
                        <Col md={5} className="text-right">
                          <span className="mr-1 fw-600 text-dark">{invoiceData.invoice_view && invoiceData.invoice_view.amount ? new Intl.NumberFormat({ style: 'currency', currency: 'JPY' }).format((invoiceData.invoice_view.amount).toFixed(2)) : 0}</span>
                          <span className="fw-600 text-dark">{invoiceData.invoice_view && invoiceData.invoice_view.currency ? invoiceData.invoice_view.currency : ""}</span>
                        </Col>
                      </Row>
                      <div className="d-flex align-items-center justify-between">
                        <div>
                          <label className="fw-500 text-dark font-size-14 d-block m-0">{_T("Available Payment Options")}</label>
                          <img src={paymentOptions} alt="Payment Options" width={200} />
                        </div>
                        <button className="btn btn-primary" type="submit">{_T("Pay Now")}</button>
                      </div>
                    </form>
                }
              </div>
            </Card>
          </div>
        </Row>

      </div>
    </div>
  );
};

export default PaymentPage;