// 3 hour slots
export const slots = [
    {
      "minTime": "1705093200000",
      "maxTime": "1705103999000",
      "start": "12:00 AM",
      "end": "02:59 AM",
      "validation": "T02:59:00",
      "displayTime": "00:00 - 02:59"
    },
    {
      "minTime": "1705017600000",
      "maxTime": "1705028399000",
      "start": "02:00 AM",
      "end": "05:59 AM",
      "validation": "T05:59:00",
      "displayTime": "03:00 - 05:59"
    },
    {
      "minTime": "1705028400000",
      "maxTime": "1705039199000",
      "start": "06:00 AM",
      "end": "08:59 AM",
      "validation": "T08:59:00",
      "displayTime": "06:00 - 08:59"
    },
    {
      "minTime": "1705039200000",
      "maxTime": "1705049999000",
      "start": "09:00 AM",
      "end": "11:59 AM",
      "validation": "T11:59:00",
      "displayTime": "09:00 - 11:59"
    },
    {
      "minTime": "1705050000000",
      "maxTime": "1705060799000",
      "start": "12:00 PM",
      "end": "02:59 PM",
      "validation": "T14:59:00",
      "displayTime": "12:00 - 14:59"
    },
    {
      "minTime": "1705060800000",
      "maxTime": "1705071599000",
      "start": "03:00 PM",
      "end": "05:59 PM",
      "validation": "T17:59:00",
      "displayTime": "15:00 - 17:59"
    },
    {
      "minTime": "1705071600000",
      "maxTime": "1705082399000",
      "start": "06:00 PM",
      "end": "08:59 PM",
      "validation": "T20:59:00",
      "displayTime": "18:00 - 20:59"
    },
    {
      "minTime": "1705082400000",
      "maxTime": "1705093199000",
      "start": "09:00 PM",
      "end": "11:59 PM",
      "validation": "T23:59:00",
      "displayTime": "21:00 - 23:59"
    }
];

// 6 hour slots
// export const slots = [
//     {
//         "minTime": "1705093200000",
//         "maxTime": "1705028399000",
//         "start": "12:00 AM",
//         "end": "05:59 AM",
//         "validation": "T05:59:00",
//         "displayTime": "00:00 - 05:59"
//     },
//     {
//         "minTime": "1705028400000",
//         "maxTime": "1705049999000",
//         "start": "6:00 AM",
//         "end": "11:59 AM",
//         "validation": "T11:59:00",
//         "displayTime": "06:00 - 11:59"
//     },
//     {
//         "minTime": "1705050000000",
//         "maxTime": "1705071599000",
//         "start": "12:00 PM",
//         "end": "05:59 PM",
//         "validation": "17:59:00",
//         "displayTime": "12:00 - 17:59"
//     },
//     {
//         "minTime": "1705071600000",
//         "maxTime": "1705093199000",
//         "start": "6:00 PM",
//         "end": "11:59 PM",
//         "validation": "T23:59:00",
//         "displayTime": "18:00 - 23:59"
//     }
// ];