import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/tawkeel-footer-logo.png'
import visa from '../assets/images/VISA.png'
import mada from '../assets/images/mada.png'
import mastercard from '../assets/images/Mastercard.png'
import applePay from '../assets/images/apple-pay.png'
import maroof from '../assets/images/maroof-certificate-logo.png'
import ecommerce from '../assets/images/ecommerce-certificate-logo.png'
import footerLogo01 from '../assets/images/footer-logo-01.png'
import footerLogo02 from '../assets/images/footer-logo-02.png'
import googleStore from '../assets/images/google-play-download.png'
import appleStore from '../assets/images/apple-store-download.png'
import appGallery from '../assets/images/Appgallery.png'
import '../assets/css/footer.css'
import _T from '../locale/Footer/translator'
import { sendTwitterConversion } from '../common/PixelEvents';

function Footer({ language }) {

    const year = new Date().getFullYear();
    return (
        <footer>
            <div className='wrapper footer-wrapper'>
                <div className='footer-row'>
                    <div className='footer-column'>
                        <img src={logo} className='footer-logo' alt="Tawkeel" />
                        <p className='text-paragraph footer-text mb-0'>{_T("TAWKEEL COMPANY FOR COMMERCIAL SERVICES is a Saudi company")}</p>
                        <p className='text-paragraph footer-text mb-0'>{_T("Keeping pace with the Kingdom's Vision 2030")}</p>
                        <ul className='social-list'>
                            <li><a target="_blank" rel="noreferrer" href='https://www.facebook.com/tawkeelapp1'><i className="ri-facebook-fill font-escape"></i></a></li>
                            <li><a target="_blank" rel="noreferrer" href='https://twitter.com/TawkeelApp'><i className="ri-twitter-x-fill font-escape"></i></a></li>
                            <li><a target="_blank" rel="noreferrer" href='https://www.instagram.com/tawkeelapp'><i className="ri-instagram-fill font-escape"></i></a></li>
                            <li><a target="_blank" rel="noreferrer" href='https://www.youtube.com/channel/UCy-5lyBTuonJgD8McKwdy3g'><i className="ri-youtube-fill font-escape"></i></a></li>
                        </ul>
                    </div>
                    <div className='footer-column'>
                        <h4 className='column-header'>{_T("Quick Links")}</h4>
                        <ul className='footer-navigation'>
                            <li><Link to={language.code === "en" ? "/en/#home" : "/#home"}>{_T("Home")}</Link></li>
                            <li><Link to={language.code === "en" ? "/en/#about" : "/#about"}>{_T("About")}</Link></li>
                            <li><Link to={language.code === "en" ? "/en/#contact" : "/#contact"}>{_T("Contact")}</Link></li>
                            <li><Link to={language.code === "en" ? "/en/frequently-asked-questions" : "/frequently-asked-questions"}>{_T("FAQ")}</Link></li>
                            <li><Link to={language.code === "en" ? "/en/privacy-policy" : "/privacy-policy"}>{_T("Privacy Policy")}</Link></li>
                            <li><Link to={language.code === "en" ? "/en/terms-and-conditions" : "/terms-and-conditions"}>{_T("Terms of Use")}</Link></li>
                        </ul>
                    </div>
                    <div className='footer-column'>
                        <h4 className='column-header'>{_T("Contact Us")}</h4>
                        <ul className='contact-list'>
                            <li><i className="ri-map-pin-line"></i> <div>{_T("Ash Shati District, Jeddah")} <span className='d-inline-block escape'>23414</span></div></li>
                            <li><i className="ri-mail-line"></i>  <a href="mailto:support@tawkeel.com">support@tawkeel.com</a></li>
                            <li><i className="ri-smartphone-line"></i> <a href="tel:8002442203">{_T("Toll free")} <span className='d-inline-block escape'>800 244 2203</span></a></li>
                            <li><i className="ri-whatsapp-line"></i> <a target='_blank' rel="noreferrer" href="https://wa.me/+9668002442203">{_T("International")} <span className='d-inline-block escape'>+966 800 244 2203</span></a></li>
                        </ul>
                    </div>
                    <div className='footer-column'>
                        <h4 className='column-header'>{_T("Download")}</h4>
                        <p className='text-paragraph'>{_T("The opportunity and ability to find someone to perform Umrah on your behalf at any time.")}</p>
                        <div className='btn-group mb-3'>
                            <a onClick={() => { sendTwitterConversion() }} href='https://tawkeel.onelink.me/u5qw/eea6bb58' rel='noreferrer' target="_blank" className='btn img-btn hover-up'>
                                <img src={googleStore} alt="GooglePlay Store" />
                            </a>
                            <a onClick={() => { sendTwitterConversion() }} href='https://tawkeel.onelink.me/u5qw/eea6bb58' rel='noreferrer' target="_blank" className='btn img-btn hover-up'>
                                <img src={appleStore} alt="Apple Store" />
                            </a>
                            {/* <a onClick={() => { sendTwitterConversion() }} href='https://apps.apple.com/us/app/tawkeel-%D8%AA%D9%88%D9%83%D9%8A%D9%84/id1583074584' rel='noreferrer' target="_blank" className='btn img-btn hover-up'>
                                <img src={appGallery} alt="App Gallery" />
                            </a> */}
                        </div>
                        <div className='payment-methods'>
                            <img src={mada} alt="Payment Method - Mada" />
                            <img src={visa} alt="Payment Method - VISA" />
                            <img src={mastercard} alt="Payment Method - MasterCard" />
                            <img src={applePay} alt="Payment Method - Apple Pay" />
                        </div>
                        <div className='certificate-list'>
                            <div className='certificate'>
                                <img src={maroof} />
                                <p>Certificate No <span className='d-block'>306523</span></p>
                            </div>
                            <div className='certificate'>
                                <img src={ecommerce} />
                                <p>Certificate No <span className='d-block'>0000008878</span></p>
                            </div>
                        </div>
                        <div className='additional-logo mt-3'>
                            <img src={footerLogo02} width={200} alt='' />
                            <img src={footerLogo01} width={120} alt='' className='ml-1' style={{maxWidth: "57px"}} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='copyright'><p>{_T("All rights reserved")} © {year}</p></div>
        </footer>
    )
}

export default Footer;