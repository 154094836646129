import React from 'react'
import { Link } from 'react-router-dom'
import helpCenterHero from '../../assets/images/privacy-policy.png'
import mainLogo from "../../assets/images/agent-agency-logo.png"
import _T from '../../locale/PrivacyPolicy/translator';
import "../../assets/css/agent-agency.css";
import Navbar from "../../components/NavbarInner";
import Footer from "../../components/FooterInner";
import {languages} from '../../common/languages'

function Hero({ heading, language }) {
    return (
        <>
            <Navbar language={languages[language]} />
            <section id='agentAgencyHero'>
                <div className='wrapper agentAgencyHero-wrapper'>
                    <div className='section-left' style={{textAlign: "left"}}>
                        <img src={mainLogo} alt="Tawkeel" />
                        <h1 className='primary-heading' style={{color: "#000"}}>{"Tawkeel"}</h1>
                        <p className='sub-heading' style={{color: "#000"}}>{_T("Agent Agency")}</p>
                        <div className='btn-group'>
                            <Link to={language.text === "English" ? "/en/quick-register" : "/quick-register"} className='btn btn-download mt-1'>{_T("SIGN UP")}</Link>
                            <Link to={language.text === "English" ? "/login" : "/login"} className='btn btn-download mt-1'>{_T("SIGN IN")}</Link>
                        </div>
                    </div>
                    <div className='section-right'>
                        {/* <img src={helpCenterHero} alt="Privacy Policy"/> */}
                    </div>
                </div>
            </section>
            <Footer language={languages[language]} />
        </>
    )
}

export default Hero;