import React, { useState } from 'react';
import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";
import { Link, useNavigate } from 'react-router-dom';
// import images
import { forgotPassword } from '../../helper/api-methods';
import Navbar from '../../components/NavbarInner';
import Footer from '../../components/FooterInner';
import mainLogo from "../../assets/images/login-logo.png"
import { languages } from '../../common/languages'
import _T from '../../locale/AgencyPortal/translator';

function ForgotPassword({ language }) {

    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(false);
    const [errors, setErrors] = useState({ username: "", apiError: "" });
    const history = useNavigate();

    let direction = languages[language].direction
    if (direction === "RTL") {
        document.getElementsByTagName("body")[0].classList.add("RTL");
    } else {
        document.getElementsByTagName("body")[0].classList.remove("RTL");
    }

    const validateSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const error = { username: "", apiError: "" }
        let flag = false;
        if (!username || username.replaceAll(/\s/g, '').length < 3 || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(username)) {
            error.username = _T("Please provide a valid email address");
            flag = true
        }
        setErrors(error);
        if (!flag) {
            forgotPassword("/forgot-Password", { email: username.toLowerCase() }).then(({ data }) => {
                setApiResponse(true)
                setLoading(false)
            }).catch((e) => {
                const error = { username: "", apiError: e }
                setErrors(error);
                setLoading(false)
            })
        }
        else {
            setLoading(false)
        }
    }

    return (
        <>
            <Navbar language={languages[language]} />
            <section id='agentAgencyHero'>
                <div className='wrapper agentAgencyHero-wrapper'>
                    <div className='section-left'>
                        <img src={mainLogo} alt="Tawkeel" />
                        <h1 className='secondary-heading' style={{color: "#000"}}>{_T("Forgot Password?")}</h1>
                        {errors.apiError ? <Alert color="danger">{_T(errors.apiError)}</Alert> : null}
                        <div className="p-2 mt-5">
                            <form className="form-horizontal" onSubmit={validateSubmit}>
                                <div className="auth-form-group-custom mb-4">
                                    <i className="ri-user-3-line auti-custom-input-icon"></i>
                                    <Input name="username" value={username} onChange={(e) => { setUsername(e.target.value.toLowerCase()) }} type="text" className={`form-control ${errors.username ? "is-invalid" : ""}`} id="username" placeholder={_T("Enter Account Email")} />
                                    {errors.username ? <div className="invalid-feedback">{errors.username}</div> : null}
                                </div>
                                <div className="mt-4 text-center">
                                    {
                                        apiResponse ? <></> :
                                            <button disabled={loading} className="btn-login" type="submit">{loading ? _T("Please Wait") : _T("Continue")}</button>
                                    }
                                </div>
                                {apiResponse ? <Alert color="success">{_T("A reset link has been sent to your email address.")}</Alert> : null}
                                <div className="mt-4 text-center">
                                    <Link to="/login" className="text-muted login-links"><i className="mdi mdi-lock me-1"></i>{_T("Back to login")}</Link>
                                </div>

                            </form>
                        </div>
                        <div className='btn-group hero-btn-group'>

                        </div>
                    </div>
                    <div className='section-right'>
                        {/* <img src={helpCenterHero} alt="Privacy Policy"/> */}
                    </div>
                </div>
            </section>
            <Footer language={languages[language]} />
        </>
    );
}

export default ForgotPassword;