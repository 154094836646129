const relationtypes = {
  "relationtype1": "Myself",
  "relationtype2": "My Mother",
  "relationtype3": "My Father",
  "relationtype4": "My Wife",
  "relationtype7": "My Brother",
  "relationtype9": "My Husband",
  "relationtype10": "My Sister",
  "relationtype8": "Other"
}
const findRelationFromString = (text) => {
  const entry = Object.entries(relationtypes).find(([key, value]) => value === text);
  return entry ? entry[0] : "";
}
export { relationtypes, findRelationFromString };