import React from "react";
import { Navigate } from "react-router-dom";

export const AgentPrivateRoute = (props) => {
  const accessToken = localStorage.getItem("authToken");
  const userObject = localStorage.getItem("user");
  const isAuthenticated = accessToken && accessToken.length ? true : false;
  if (isAuthenticated) {
    if (JSON.parse(userObject)) {
      return props.children;
    } else {
      return <Navigate to="/" />;
    }
  } else {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    localStorage.removeItem("refresh");
    localStorage.removeItem("expiry");
    localStorage.removeItem("reset");
    return <Navigate to="/" />;
  }
};
export const UnauthRoute = (props) => {
  return props.children;
};
