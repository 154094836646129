import React, { useState } from 'react'
import mainLogo from "../../assets/images/main-logo.png"
import _T from '../../locale/Agents/translator';
import { Link } from 'react-router-dom'
import heroImage from "../../assets/images/screenshots/screenshot.png"
import heroVideo from "../../assets/videos/Hero-video-tawkeel.mp4"

function section_scroll(e, id) {
    e.preventDefault();
    let scrollDiv = document.getElementById(id).offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
}
function Hero({ language }) {
    const [videoLoaded, setVideoLoaded] = useState(false);

    const handleVideoLoad = () => {
        setVideoLoaded(true);
    };
    return (
        <section id='helpCenterHome'>
            <div
                className={`image-fallback ${videoLoaded ? 'hidden' : ''}`}
                style={{ backgroundImage: `url(${heroImage})` }}
            ></div>
            <video
                className={`video-background ${videoLoaded ? '' : 'hidden'}`}
                onCanPlayThrough={handleVideoLoad}
                autoPlay
                loop
                muted
                playsInline
            >
                <source src={"https://tawkeelprod.blob.core.windows.net/upload-image/Hero-video-tawkeel.mp4"} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="home-overlay">
            </div>
            <div className='wrapper helpCenterHome-wrapper'>
                <div className='section-left'>
                    <img src={mainLogo} alt="Tawkeel" />
                    <h1 className='primary-heading'>{_T("Agents")}</h1>
                    <p className='text-paragraph'>{_T("Tawkeel application partners with a wide network of accredited offices and external agents across the world to showcase our Umrah services to Muslims around the world.")}</p>
                    {/* <div className='btn-group'>
                        <a href='#' onClick={(e)=>{section_scroll(e,'agents')}} className='btn btn-primary'>{_T("Find Agent")}</a>
                    </div> */}
                    <div className='btn-group'>
                        <Link to={language && language.text === "English" ? "/en/quick-register" : "/quick-register"} className='btn btn-download mt-1'>{_T("SIGN UP")}</Link>
                        <Link to={language && language.text === "English" ? "/login" : "/login"} className='btn btn-download mt-1'>{_T("SIGN IN")}</Link>
                    </div>
                </div>
                <div className='section-right'>
                    {/* <img src={helpCenterHero} /> */}
                </div>
            </div>
        </section>
    )
}

export default Hero;