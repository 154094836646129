function _T(text) {
    const language = localStorage.getItem("lang") ? localStorage.getItem("lang") === "ar" ? "ar" : "en" : "ar";
    const translations = {
        "Order Management": {
            "en": "Order Management",
            "ar": "إدارة الطلبات",
            "ur": ""
        },
        "Payment Page": {
            "en": "Payment Page",
            "ar": "صفحة الدفع",
            "ur": ""
        },
        "Purchased Codes": {
            "en": "Purchased Codes",
            "ar": "الرموز التي تم شراؤها",
            "ur": ""
        },
        "Invoices": {
            "en": "Invoices",
            "ar": "الفواتير",
            "ur": ""
        },
        "Report and Analysis": {
            "en": "Report and Analysis",
            "ar": "التقارير وتحليل الأداء",
            "ur": ""
        },
        "My Profile": {
            "en": "My Profile",
            "ar": "الملف الشخصي",
            "ur": ""
        },
        "Search Orders": {
            "en": "Search Orders",
            "ar": "بحث",
            "ur": ""
        },
        "Search by Order ID or Seeker Name": {
            "en": "Search by Order ID or Seeker Name",
            "ar": "البحث حسب رقم الطلب أو اسم الموكل",
            "ur": ""
        },
        "Filter by Country": {
            "en": "Filter by Country",
            "ar": "تصفية حسب البلد",
            "ur": ""
        },
        "Show All": {
            "en": "Show All",
            "ar": "إظهار الكل",
            "ur": ""
        },
        "Filter by Status": {
            "en": "Filter by Status",
            "ar": "البحث عن  الحاله",
            "ur": ""
        },
        "Filter by Date": {
            "en": "Filter by Date",
            "ar": "تصفية حسب التاريخ",
            "ur": ""
        },
        "Select Date Range": {
            "en": "Select Date Range",
            "ar": "الفترة الزمنية",
            "ur": ""
        },
        "Show Pending Orders": {
            "en": "Show Pending Orders",
            "ar": "الطلبات المعلقة",
            "ur": ""
        },
        "Show Paid Orders": {
            "en": "Show Paid Orders",
            "ar": "الطلبات المدفوعة",
            "ur": ""
        },
        "Show Waiting for Performer Orders": {
            "en": "Show Waiting for Performer Orders",
            "ar": "طلبات معلقة بإنتظار المؤدين",
            "ur": ""
        },
        "Show Waiting for Seeker Orders": {
            "en": "Show Waiting for Seeker Orders",
            "ar": "طلبات معلقة بإنتظار الموكل",
            "ur": ""
        },
        "Show Accepted Orders": {
            "en": "Show Accepted Orders",
            "ar": "الطلبات المقبولة",
            "ur": ""
        },
        "Show Rejected Orders": {
            "en": "Show Rejected Orders",
            "ar": "الطلبات المرفوضة",
            "ur": ""
        },
        "Show Cancelled Orders": {
            "en": "Show Cancelled Orders",
            "ar": "الطلبات الملغاة",
            "ur": ""
        },
        "Show In-Progress Orders": {
            "en": "Show In-Progress Orders",
            "ar": "طلبات قيد التنفيذ",
            "ur": ""
        },
        "Show Completed Orders": {
            "en": "Show Completed Orders",
            "ar": "الطلبات المكتملة",
            "ur": ""
        },
        "Show Refunded Orders": {
            "en": "Show Refunded Orders",
            "ar": "الطلبات المستردة",
            "ur": ""
        },
        "Create Umrah Order": {
            "en": "Create Umrah Order",
            "ar": "إنشاء طلب عمرة بدل",
            "ur": ""
        },
        "Create Manually": {
            "en": "Create Manually",
            "ar": "إنشاء يدويًا",
            "ur": ""
        },
        "Upload File": {
            "en": "Upload File",
            "ar": "تحميل الملف",
            "ur": ""
        },
        "Logout": {
            "en": "Logout",
            "ar": "تسجيل خروج",
            "ur": ""
        },
        "Order ID": {
            "en": "Order ID",
            "ar": "رقم الطلب",
            "ur": ""
        },
        "Seeker": {
            "en": "Seeker",
            "ar": "طالب العمرة",
            "ur": ""
        },
        "Country": {
            "en": "Country",
            "ar": "الدولة",
            "ur": ""
        },
        "Code": {
            "en": "Code",
            "ar": "الرمز",
            "ur": ""
        },
        "Payment": {
            "en": "Payment",
            "ar": "المدفوعات",
            "ur": ""
        },
        "Paid": {
            "en": "Paid",
            "ar": "تم الدفع",
            "ur": ""
        },
        "Pending": {
            "en": "Pending",
            "ar": "قيد الانتظار",
            "ur": ""
        },
        "Status": {
            "en": "Status",
            "ar": "الحالة",
            "ur": ""
        },
        "Payment Done": {
            "en": "Payment Done",
            "ar": "الدفع مكتمل",
            "ur": ""
        },
        "Waiting for Performer": {
            "en": "Waiting for Performer",
            "ar": "بإنتظار المؤدي",
            "ur": ""
        },
        "Cancelled": {
            "en": "Cancelled",
            "ar": "تم إلغاء الطلب",
            "ur": ""
        },
        "Accepted": {
            "en": "Accepted",
            "ar": "تم قبول الطلب",
            "ur": ""
        },
        "In-Progress": {
            "en": "In-Progress",
            "ar": "قيد التنفيذ",
            "ur": ""
        },
        "Refunded": {
            "en": "Refunded",
            "ar": "تم استرداد المبلغ",
            "ur": ""
        },
        "Completed": {
            "en": "Completed",
            "ar": "تم إكتمال الطلب",
            "ur": ""
        },
        "Order Date": {
            "en": "Order Date",
            "ar": "تاريخ الطلب",
            "ur": ""
        },
        "Assigned": {
            "en": "Assigned",
            "ar": "محدد",
            "ur": ""
        },
        "Unassigned": {
            "en": "Unassigned",
            "ar": "غير محدد",
            "ur": ""
        },
        "Service Start": {
            "en": "Service Start",
            "ar": "بدء التنفيذ",
            "ur": ""
        },
        "Service": {
            "en": "Service",
            "ar": "الخدمة",
            "ur": ""
        },
        "Showing": {
            "en": "Showing",
            "ar": "عرض",
            "ur": ""
        },
        "to": {
            "en": "to",
            "ar": "الى",
            "ur": ""
        },
        "to ": {
            "en": "to ",
            "ar": " الى",
            "ur": ""
        },
        "of": {
            "en": "of",
            "ar": "من",
            "ur": ""
        },
        "Results": {
            "en": "Results",
            "ar": "النتائج",
            "ur": ""
        },
        "First": {
            "en": "First",
            "ar": "الاول",
            "ur": ""
        },
        "Previous": {
            "en": "Previous",
            "ar": "السابق",
            "ur": ""
        },
        "Next": {
            "en": "Next",
            "ar": "التالي",
            "ur": ""
        },
        "Last": {
            "en": "Last",
            "ar": "الأخير",
            "ur": ""
        },
        "Umrah Packages": {
            "en": "Umrah Packages",
            "ar": "عمرة بدل (حجوزات متعددة)",
            "ur": ""
        },
        "To purchase Umrah Packages, please select the number of Umrah Packages you would like to purchase": {
            "en": "To purchase Umrah Packages, please select the number of Umrah Packages you would like to purchase",
            "ar": "لشراء أكواد خدمة عمرة البدل (حجوزات متعددة)، يرجى اختيار العدد المطلوب",
            "ur": ""
        },
        "Custom": {
            "en": "Custom",
            "ar": "تخصيص",
            "ur": ""
        },
        "Quantity": {
            "en": "Quantity",
            "ar": "الكمية",
            "ur": ""
        },
        "Sub Total": {
            "en": "Sub Total",
            "ar": "الإجمالي الفرعي",
            "ur": ""
        },
        "Sub-Total": {
            "en": "Sub-Total",
            "ar": "الإجمالي الفرعي",
            "ur": ""
        },
        "VAT Applied": {
            "en": "VAT Applied",
            "ar": "تم إضافة ضريبة القيمة المضافة",
            "ur": ""
        },
        "Calculated on checkout": {
            "en": "Calculated on checkout",
            "ar": "يتم احتسابها عند إتمام عملية الدفع",
            "ur": ""
        },
        "Total": {
            "en": "Total",
            "ar": "الإجمالي",
            "ur": ""
        },
        "Proceed to Checkout": {
            "en": "Proceed to Checkout",
            "ar": "المتابعة لإتمام الدفع",
            "ur": ""
        },
        "Please Wait": {
            "en": "Please Wait",
            "ar": "يرجى الانتظار",
            "ur": ""
        },
        "Umrah Vouchers": {
            "en": "Umrah Vouchers",
            "ar": "قسائم أداء العمرة",
            "ur": ""
        },
        "Pay Now": {
            "en": "Pay Now",
            "ar": "ادفع الان",
            "ur": ""
        },
        "Available Payment Options": {
            "en": "Available Payment Options",
            "ar": "خيارات الدفع المتاحة",
            "ur": ""
        },
        "Search Discount Code": {
            "en": "Search Discount Code",
            "ar": "البحث عن رمز الخصم",
            "ur": ""
        },
        "Type here to search": {
            "en": "Type here to search",
            "ar": "اضغط هنا للبحث",
            "ur": ""
        },
        "All Statuses": {
            "en": "All Statuses",
            "ar": "كل الحالات",
            "ur": ""
        },
        "Used": {
            "en": "Used",
            "ar": "المستخدم",
            "ur": ""
        },
        "Saved": {
            "en": "Saved",
            "ar": "حفظ",
            "ur": ""
        },
        "Available": {
            "en": "Available",
            "ar": "متاح",
            "ur": ""
        },
        "Discount": {
            "en": "Discount",
            "ar": "خصم",
            "ur": ""
        },
        "Limit": {
            "en": "Limit",
            "ar": "حد",
            "ur": ""
        },
        "Once": {
            "en": "Once",
            "ar": "مره واحده",
            "ur": ""
        },
        "Usage": {
            "en": "Usage",
            "ar": "الاستخدام",
            "ur": ""
        },
        "Status": {
            "en": "Status",
            "ar": "الحاله",
            "ur": ""
        },
        "Your Invoices": {
            "en": "Your Invoices",
            "ar": "الفاتوره",
            "ur": ""
        },
        "Following is a list of all paid and unpaid invoices": {
            "en": "Following is a list of all paid and unpaid invoices",
            "ar": "جميع الفواتير المدفوعة والغير مدفوعة",
            "ur": ""
        },
        "Invoice ID": {
            "en": "Invoice ID",
            "ar": "رقم الفاتوره",
            "ur": ""
        },
        "Invoice Date": {
            "en": "Invoice Date",
            "ar": "تاريخ الفاتوره",
            "ur": ""
        },
        "Card": {
            "en": "Card",
            "ar": "البطاقه",
            "ur": ""
        },
        "Vouchers": {
            "en": "Vouchers",
            "ar": "قسائم",
            "ur": ""
        },
        "Amount": {
            "en": "Amount",
            "ar": "المبلغ",
            "ur": ""
        },
        "Tax (VAT)": {
            "en": "Tax (VAT)",
            "ar": "الرقم الضريبي",
            "ur": ""
        },
        "Status": {
            "en": "Status",
            "ar": "الحاله",
            "ur": ""
        },
        "Action": {
            "en": "Action",
            "ar": "خطوه",
            "ur": ""
        },
        "Show Invoice": {
            "en": "Show Invoice",
            "ar": "اظهار الفاتوره",
            "ur": ""
        },
        "Number of Seekers": {
            "en": "Number of Seekers",
            "ar": "رقم الموكل",
            "ur": ""
        },
        "Total number of Seekers": {
            "en": "Total number of Seekers",
            "ar": "مجموع عدد الموكلين",
            "ur": ""
        },
        "Total Orders": {
            "en": "Total Orders",
            "ar": "مجموع عدد الطلبات",
            "ur": ""
        },
        "Number of orders created": {
            "en": "Number of orders created",
            "ar": "عدد الطلبات المنشاءه",
            "ur": ""
        },
        "Umrah in Process": {
            "en": "Umrah in Process",
            "ar": "عمره تحت الطلب",
            "ur": ""
        },
        "Number of Umrah in process": {
            "en": "Number of Umrah in process",
            "ar": "عدد العمرات تحت الطلب",
            "ur": ""
        },
        "Umrah Completed": {
            "en": "Umrah Completed",
            "ar": "العمرات المكتمله",
            "ur": ""
        },
        "Number of Umrah completed": {
            "en": "Number of Umrah completed",
            "ar": "عدد العمرات المكتمله",
            "ur": ""
        },
        "Umrah Package Analytics": {
            "en": "Umrah Package Analytics",
            "ar": "تحليل مجموعه العمرات",
            "ur": ""
        },
        "Umrah Total": {
            "en": "Umrah Total",
            "ar": "مجموع العمرات",
            "ur": ""
        },
        "Total number of Umrah": {
            "en": "Total number of Umrah",
            "ar": "مجموع العمرات كامله",
            "ur": ""
        },
        "Umrah Sold": {
            "en": "Umrah Sold",
            "ar": "العمرات المباعه",
            "ur": ""
        },
        "Number of Umrah Sold": {
            "en": "Number of Umrah Sold",
            "ar": "مجموع العمرات المباعه",
            "ur": ""
        },
        "Number of Umrah Available": {
            "en": "Number of Umrah Available",
            "ar": "عدد العمرات المتاحه",
            "ur": ""
        },
        "Total": {
            "en": "Total",
            "ar": "المجموع",
            "ur": ""
        },
        "Used": {
            "en": "Used",
            "ar": "المستخدم",
            "ur": ""
        },
        "Saved": {
            "en": "Saved",
            "ar": "حفظ",
            "ur": ""
        },
        "Available": {
            "en": "Available",
            "ar": "متاح",
            "ur": ""
        },
        "Company Name": {
            "en": "Company Name",
            "ar": "اسم الشركه",
            "ur": ""
        },
        "Agent's Country": {
            "en": "Agent's Country",
            "ar": "دولة الوكاله",
            "ur": ""
        },
        "Agent's Email": {
            "en": "Agent's Email",
            "ar": "ايميل الوكاله",
            "ur": ""
        },
        "Agent's Phone Number": {
            "en": "Agent's Phone Number",
            "ar": "رقم التواصل للوكاله",
            "ur": ""
        },
        "Create Orders": {
            "en": "Create Orders",
            "ar": "انشاء طلب",
            "ur": ""
        },
        "Upload File: (Max: 5MB, Supports: .xlsx)": {
            "en": "Upload File: (Max: 5MB, Supports: .xlsx)",
            "ar": "رفع الملف ( اقصى حجم 5MB, ملف xlsx )",
            "ur": ""
        },
        "Drop File Here (Max: 5MB, Supports: .xlsx)": {
            "en": "Drop File Here (Max: 5MB, Supports: .xlsx)",
            "ar": "اسحب الملف هنا",
            "ur": ""
        },
        "Multiple": {
            "en": "Multiple",
            "ar": "متعدد",
            "ur": ""
        },
        "Download": {
            "en": "Download",
            "ar": "تنزيل",
            "ur": ""
        },
        "Sample sheet": {
            "en": "Sample sheet",
            "ar": "مثال للملف",
            "ur": ""
        },
        "Drop File here": {
            "en": "Drop File here",
            "ar": "اسحب الملف هنا",
            "ur": ""
        },
        "Close": {
            "en": "Close",
            "ar": "اغلاق",
            "ur": ""
        },
        "Preview": {
            "en": "Preview",
            "ar": "استعراض",
            "ur": ""
        },
        "Clear File": {
            "en": "Clear File",
            "ar": "حذف الملف",
            "ur": ""
        },
        "Customer Name ": {
            "en": "Customer Name",
            "ar": "اسم الموكل/طالب العمرة",
            "ur": ""
        },
        "Customer Name": {
            "en": "Customer Name",
            "ar": "رقم العميل",
            "ur": ""
        },
        "Customer Email ": {
            "en": "Customer Email ",
            "ar": "البريد الالكتروني",
            "ur": ""
        },
        "Customer Email": {
            "en": "Customer Email",
            "ar": "البريد الالكتروني للعميل",
            "ur": ""
        },
        "Customer Phone": {
            "en": "Customer Phone",
            "ar": "رقم هاتف العميل",
            "ur": ""
        },
        "Discount Code": {
            "en": "Discount Code",
            "ar": "رمز الخصم",
            "ur": ""
        },
        "On who's behalf should it be performed?": {
            "en": "On who's behalf should it be performed?",
            "ar": "عن من تُؤدى العمرة بالنيابة؟",
            "ur": ""
        },
        "Is Deceased?": {
            "en": "Is Deceased?",
            "ar": "هل الشخص متوفي؟",
            "ur": ""
        },
        "Go Back": {
            "en": "Go Back",
            "ar": "الرجوع",
            "ur": ""
        },
        "Yes": {
            "en": "Yes",
            "ar": "نعم",
            "ur": ""
        },
        "No": {
            "en": "No",
            "ar": "لا",
            "ur": ""
        },
        "Proceed with [#] records": {
            "en": "Proceed with [#] records",
            "ar": "تابع بالتسجيل",
            "ur": ""
        },
        "Creating Umrah Orders": {
            "en": "Creating Umrah Orders",
            "ar": "إنشاء طلب عمرة",
            "ur": ""
        },
        "Processing Umrah Orders": {
            "en": "Processing Umrah Orders",
            "ar": "معالجة طلب العمره",
            "ur": ""
        },
        "# order(s) could not be created because of the following reasons:": {
            "en": "# order(s) could not be created because of the following reasons:",
            "ar": "لا يمكن انشاء الطلب بسبب الاسباب التاليه",
            "ur": ""
        },
        "Order No.": {
            "en": "Order No.",
            "ar": "رقم الطلب",
            "ur": ""
        },
        "Unable to apply code at this time. Please contact Support for more information": {
            "en": "Unable to apply code at this time. Please contact Support for more information",
            "ar": "الرمز غير متاح حالياً, الرجاء التواصل مع الدعم للمزيد من المعلومات",
            "ur": ""
        },
        "Error Code": {
            "en": "Error Code",
            "ar": "الرمز خطأ",
            "ur": ""
        },
        "All the orders were successfully created.": {
            "en": "All the orders were successfully created.",
            "ar": "تم انشاء كل الطلبات بنجاح",
            "ur": ""
        },
        "There is an issue with the data on Row[1] Column 'Customer Name'": {
            "en": "There is an issue with the data on Row[1] Column 'Customer Name'",
            "ar": "هنالك خطاء بالبيانات (1) الخانه اسم العميل",
            "ur": ""
        },
        "There is an issue with the data on Row[1] Column 'Customer Email'": {
            "en": "There is an issue with the data on Row[1] Column 'Customer Email'",
            "ar": "هنالك خطاء بالبيانات(1) الخانه البريد الالكتروني للعميل",
            "ur": ""
        },
        "There is an issue with the data on Row[1] Column 'Customer Phone'": {
            "en": "There is an issue with the data on Row[1] Column 'Customer Phone'",
            "ar": "هنالك خطاء بالبيانات (1) الخانه رقم هاتف العميل",
            "ur": ""
        },
        "There is an issue with the data on Row[1] Column 'Discount Code'": {
            "en": "There is an issue with the data on Row[1] Column 'Discount Code'",
            "ar": "هنالك خطاء بالبيانات (1) الخانه رمز الخصم للعميل",
            "ur": ""
        },
        "There is an issue with the data on Row[1] Column 'On who's behalf should it be performed?'": {
            "en": "There is an issue with the data on Row[1] Column 'On who's behalf should it be performed?'",
            "ar": "هنالك خطاء بالبيانات (1) الخانه عن من يجب ان تكون العمره بالانابه",
            "ur": ""
        },
        "Create New Order": {
            "en": "Create New Order",
            "ar": "انشاء طلب جديد",
            "ur": ""
        },
        "Please use the following options to create a new order": {
            "en": "Please use the following options to create a new order",
            "ar": "الرجاء اتباع التعليمات التاليه لانشاء طلب جديد",
            "ur": ""
        },
        "No of Orders": {
            "en": "No of Orders",
            "ar": "لا توجد طلبات",
            "ur": ""
        },
        "Enter Customer's Name": {
            "en": "Enter Customer's Name",
            "ar": "ادخل الاسم",
            "ur": ""
        },
        "Enter Customer's Email": {
            "en": "Enter Customer's Email",
            "ar": "سجل البريد الالكتروني للعميل",
            "ur": ""
        },
        "Enter Customer's Phone Number": {
            "en": "Enter Customer's Phone Number",
            "ar": "سجل رقم هاتف العميل",
            "ur": ""
        },
        "Don't Specify": {
            "en": "Don't Specify",
            "ar": "لا تحدد",
            "ur": ""
        },
        "Myself": {
            "en": "Myself",
            "ar": "عن نفسي",
            "ur": ""
        },
        "My Mother": {
            "en": "My Mother",
            "ar": "عن امي",
            "ur": ""
        },
        "My Father": {
            "en": "My Father",
            "ar": "عن ابي",
            "ur": ""
        },
        "My Wife": {
            "en": "My Wife",
            "ar": "عن زوجتي",
            "ur": ""
        },
        "My Brother": {
            "en": "My Brother",
            "ar": "عن اخي",
            "ur": ""
        },
        "My Husband": {
            "en": "My Husband",
            "ar": "عن زوجي",
            "ur": ""
        },
        "My Sister": {
            "en": "My Sister",
            "ar": "عن اختي",
            "ur": ""
        },
        "Other": {
            "en": "Other",
            "ar": "اخر",
            "ur": ""
        },
        "Enter Discount Code": {
            "en": "Enter Discount Code",
            "ar": "ادخل رمز الخصم",
            "ur": ""
        },
        "Umrah Date": {
            "en": "Umrah Date",
            "ar": "تاريخ العمره",
            "ur": ""
        },
        "Umrah Slot (KSA timezone)": {
            "en": "Umrah Slot (KSA timezone)",
            "ar": "وقت العمره (بتوقيت السعوديه)",
            "ur": ""
        },
        "Clear": {
            "en": "Clear",
            "ar": "حذف",
            "ur": ""
        },
        "Today": {
            "en": "Today",
            "ar": "اليوم",
            "ur": ""
        },
        "Create Order": {
            "en": "Create Order",
            "ar": "انشاء الطلب",
            "ur": ""
        },
        "Passwords do not match.": {
            "en": "Passwords do not match.",
            "ar": "كلمتا المرور غير متطابقتين.",
            "ur": ""
        },
        "Please provide a valid password": {
            "en": "Please provide a valid password",
            "ar": "يرجى تقديم كلمة مرور صالحة",
            "ur": ""
        },
        "Please provide a valid email address": {
            "en": "Please provide a valid email address",
            "ar": "الرجاء تسجيل بريد الكتروني صالح",
            "ur": ""
        },
        "Please provide a valid password": {
            "en": "Please provide a valid password",
            "ar": "يرجى تقديم كلمة مرور صالحة",
            "ur": ""
        },
        "Please provide a phone number": {
            "en": "Please provide a phone number",
            "ar": "الرجاء تسجيل رقم هاتف صالح",
            "ur": ""
        },
        "Please provide a discount code for the order": {
            "en": "Please provide a discount code for the order",
            "ar": "الرجاء تسجيل رمز الخصم للطلب",
            "ur": ""
        },
        "Minimum date allowed is ": {
            "en": "Minimum date allowed is ",
            "ar": " اقل تاريخ مسموح به",
            "ur": ""
        },
        "Tawkeel Support": {
            "en": "Tawkeel Support",
            "ar": "دعم توكيل",
            "ur": ""
        },
        "This section provides information about how to create a new order": {
            "en": "This section provides information about how to create a new order",
            "ar": "هاذا القسم خاص بالمعلومات عن كيفية انشاء طلب جديد",
            "ur": ""
        },
        "This is the name of the person on whose behalf the Umrah will be performed.": {
            "en": "This is the name of the person on whose behalf the Umrah will be performed.",
            "ar": "هاذا اسم الشخص الذي تكون العمره بالنيابه عنه",
            "ur": ""
        },
        "This is the relation of the user with the person to whom the user wants to dedicate the Umrah Order.": {
            "en": "This is the relation of the user with the person to whom the user wants to dedicate the Umrah Order.",
            "ar": "هاذه صفه الرابط بين الموكل والشخص بالنيابع عنه",
            "ur": ""
        },
        "This is a discount code from your agency that should be used to create this order.": {
            "en": "This is a discount code from your agency that should be used to create this order.",
            "ar": "رمز الخصم من الوكاله الذي يجب ان يستخدم  لانشاء الطلب",
            "ur": ""
        },
        "If umrah has to be Scheduled, the umrah slot will be used to perform the umrah within the defined time as per Kingdom of Saudi Arabia Timezone": {
            "en": "If umrah has to be Scheduled, the umrah slot will be used to perform the umrah within the defined time as per Kingdom of Saudi Arabia Timezone",
            "ar": "اذا كانت العمل مجدوله , وقت العمره يجب ان تكون كوقت الممكله العربيه السعوديه",
            "ur": ""
        },
        "If umrah has to be Scheduled for a specific date, use this field to select an umrah date": {
            "en": "If umrah has to be Scheduled for a specific date, use this field to select an umrah date",
            "ar": "ان كانت العمره مخصص لوقت محدد ىالرجاء اختيار الوقت الذي تريده",
            "ur": ""
        },
        "The 'Add Another Order' button helps to create multiple orders at once. Press the button to add details for another order.": {
            "en": "The 'Add Another Order' button helps to create multiple orders at once. Press the button to add details for another order.",
            "ar": "لاضافة عمره جديده الخانه التاليه تساعدك لانشاء طلبات متعدده",
            "ur": ""
        },
        "Is Deceased?": {
            "en": "Is Deceased?",
            "ar": "متوفي",
            "ur": ""
        },
        "Is the person deceased?": {
            "en": "Is the person deceased?",
            "ar": "هل الشخص متوفي",
            "ur": ""
        },
        "Yes": {
            "en": "Yes",
            "ar": "نعم",
            "ur": ""
        },
        "No": {
            "en": "No",
            "ar": "لا",
            "ur": ""
        },
        "Request Failed": {
            "en": "Request Failed",
            "ar": "فشل الطلب",
            "ur": ""
        },
        "Discount Code not found": {
            "en": "Discount Code not found",
            "ar": "رمز الخصم غير موجود",
            "ur": ""
        },
        "Order Created": {
            "en": "Order Created",
            "ar": "تم إنشاء الطلب",
            "ur": ""
        },
        "A new order has been created and is pending approval from admin": {
            "en": "A new order has been created and is pending approval from Admin",
            "ar": "تم إنشاء طلب جديد, في انتظار موافقة المشرف",
            "ur": ""
        },
        "You are not able to login because the account is not authorized": {
            "en": "You are not able to login because the account is not authorized",
            "ar": "لا يمكنك تسجيل الدخول لأن الحساب غير مصرح به",
            "ur": ""
        },
        "User account does not exist": {
            "en": "User account does not exist",
            "ar": "حساب المستخدم غير موجود",
            "ur": ""
        },
        "No invoice found": {
            "en": "No invoice found",
            "ar": "لم يتم العثور على فاتورة",
            "ur": ""
        },
        "Password is not set": {
            "en": "Password is not set",
            "ar": "لم يتم تعيين كلمة المرور",
            "ur": ""
        },
        "Calculated on Checkout": {
            "en": "Calculated on Checkout",
            "ar": "يتم احتسابها عند إتمام عملية الدفع"
        },
        "Please Wait!": {
            "en": "Please Wait!",
            "ar": "يرجى الانتظار!"
        },
        "Once": {
            "en": "Once",
            "ar": "مره واحده"
        },
        "Sub Total": {
            "en": "Sub Total",
            "ar": "الإجمالي الفرعي"
        },
        "Customer Phone": {
            "en": "Customer Phone",
            "ar": "رقم هاتف العميل"
        },
        "order(s) could not be created because of the following reasons:": {
            "en": "order(s) could not be created because of the following reasons:",
            "ar": "لا يمكن انشاء الطلب بسبب الاسباب التاليه"
        },
        "There is an issue with the data on Row[1] Column 'Customer Name'": {
            "en": "There is an issue with the data on Row[1] Column 'Customer Name'",
            "ar": "هنالك خطاء بالبيانات (1) الخانه اسم العميل"
        },
        "There is an issue with the data on Row[1] Column 'Customer Email'": {
            "en": "There is an issue with the data on Row[1] Column 'Customer Email'",
            "ar": "هنالك خطاء بالبيانات(1) الخانه البريد الالكتروني للعميل"
        },
        "There is an issue with the data on Row[1] Column 'Customer Phone'": {
            "en": "There is an issue with the data on Row[1] Column 'Customer Phone'",
            "ar": "هنالك خطاء بالبيانات (1) الخانه رقم هاتف العميل"
        },
        "There is an issue with the data on Row[1] Column 'Discount Code'": {
            "en": "There is an issue with the data on Row[1] Column 'Discount Code'",
            "ar": "هنالك خطاء بالبيانات (1) الخانه رمز الخصم للعميل"
        },
        "There is an issue with the data on Row[1] Column 'On who's behalf should it be performed?'": {
            "en": "There is an issue with the data on Row[1] Column 'On who's behalf should it be performed?'",
            "ar": "هنالك خطاء بالبيانات (1) الخانه عن من يجب ان تكون العمره بالانابه"
        },
        "Other": {
            "en": "Other",
            "ar": "اخر"
        },
        "Please provide a valid email address": {
            "en": "Please provide a valid email address",
            "ar": "الرجاء تسجيل  بريد الكتروني صالح"
        },
        "Please provide a phone number": {
            "en": "Please provide a phone number",
            "ar": "الرجاء تسجيل رقم هاتف صالح"
        },
        "Please provide a discount code for the order": {
            "en": "Please provide a discount code for the order",
            "ar": "الرجاء تسجيل رمز الخصم للطلب"
        },
        "Minimum date allowed is ": {
            "en": "Minimum date allowed is ",
            "ar": "اقل تاريخ مسموح به "
        },
        "Discount Code not found": {
            "en": "Discount Code not found",
            "ar": "رمز الخصم غير موجود"
        },
        "Drop File here": {
            "en": "Drop File here",
            "ar": "اسحب الملف هنا"
        },
        "Log in to your Agency account": {
            "en": "Log in to your Agency account",
            "ar": "تسجيل الدخول إلى حساب الوكالة الخاص بك",
            "ur": ""
        },
        "Email": {
            "en": "Email",
            "ar": "ايميل",
            "ur": ""
        },
        "Remember Me": {
            "en": "Remember Me",
            "ar": "تذكرني",
            "ur": ""
        },
        "Forgot your Password": {
            "en": "Forgot your Password",
            "ar": "نسيت كلمة المرور",
            "ur": ""
        },
        "LOGIN": {
            "en": "LOGIN",
            "ar": "تسجيل الدخول",
            "ur": ""
        },
        "Forgot Password?": {
            "en": "Forgot Password?",
            "ar": "نسيت كلمة المرور",
            "ur": ""
        },
        "Enter Account email": {
            "en": "Enter Account email",
            "ar": "ادخل ايميل الحساب",
            "ur": ""
        },
        "Continue": {
            "en": "Continue",
            "ar": "أكمل",
            "ur": ""
        },
        "Back to Login": {
            "en": "Back to Login",
            "ar": "الرجوع الى تسجيل الدخول",
            "ur": ""
        },
        "A reset link has been sent to your email address.": {
            "en": "A reset link has been sent to your email address.",
            "ar": "تم إرسال رابط إعادة التعيين إلى عنوان بريدك الإلكتروني.",
            "ur": ""
        },
        "Support": {
            "en": "Support",
            "ar": "الدعم",
            "ur": ""
        },
        "Umrah Available": {
            "en": "Umrah Available",
            "ar": "العمرة متاحة",
            "ur": ""
        },
        "Server Error": {
            "en": "Server Error",
            "ar": "خطأ في السيرفر",
            "ur": ""
        },
        "The discount code is not valid": {
            "en": "The discount code is not valid",
            "ar": "الكود غير صالح",
            "ur": ""
        },
        "Umrah Orders Created": {
            "en": "Umrah Orders Created",
            "ar": "تم إنشاء طلبات العمرة",
            "ur": ""
        },
        "Don't Specify": {
            "en": "Don't Specify",
            "ar": "لا تحدد",
            "ur": ""
        },
        "There are currently no umrah packages available.": {
            "en": "There are currently no umrah packages available.",
            "ar": "لا يوجد حاليا أي عروض عمرة المتاحة",
            "ur": ""
        },
        "You haven't purchased any discount codes.": {
            "en": "You haven't purchased any discount codes.",
            "ar": "لم تقم بشراء أي رمز الخصم",
            "ur": ""
        },
        "You currently have no transaction records.": {
            "en": "You currently have no transaction records.",
            "ar": "ليس لديك أي معاملات جارية.",
            "ur": ""
        },
        "Unpaid": {
            "en": "Unpaid",
            "ar": "غير مدفوع",
            "ur": ""
        },
        "Download Invoice": {
            "en": "Download Invoice",
            "ar": "حمل الفاتورة",
            "ur": ""
        },
        "Hide Invoice": {
            "en": "Hide Invoice",
            "ar": "اخفي الفاتورة",
            "ur": ""
        },
        "Invoice": {
            "en": "Invoice",
            "ar": "الفاتورة",
            "ur": ""
        },
        "Agency": {
            "en": "Agency",
            "ar": "الوكالة",
            "ur": ""
        },
        "Cost": {
            "en": "Cost",
            "ar": "التكلفة",
            "ur": ""
        },
        "Add Another Order": {
            "en": "Add Another Order",
            "ar": "اضف طلب جديد",
            "ur": ""
        },
        "Umrah Slot": {
            "en": "Umrah Slot",
            "ar": "فترة العمرة",
            "ur": ""
        },
        "Customer Phone Number": {
            "en": "Customer Phone Number",
            "ar": "رقم هاتف العميل",
            "ur": ""
        },
        "Drop File Here (Max: 5MB, Supports: .xlsx)": {
            "en": "Drop File Here (Max: 5MB, Supports: .xlsx)",
            "ar": "اسحب الملف هنا ( اقصى حجم 5MB, ملف xlsx )",
            "ur": ""
        },
        "Showing 0 of 0 Results": {
            "en": "Showing 0 of 0 Results",
            "ar": "عرض 0 من 0 النتائج",
            "ur": ""
        },
        "Proceed with": {
            "en": "Proceed with",
            "ar": "المتابعة",
            "ur": ""
        },
        "Records": {
            "en": "Records",
            "ar": "السجلات",
            "ur": ""
        },
        "There is an issue with the data on Row": {
            "en": "There is an issue with the data on Row",
            "ar": "هناك مشكلة في البيانات الموجودة على الصف",
            "ur": ""
        },
        "Column": {
            "en": "Column",
            "ar": "العامود",
            "ur": ""
        },
        "There are no Umrah packages available": {
            "en": "There are no Umrah packages available",
            "ar": "لا توجد عروض عمرة المتاحة",
            "ur": ""
        },
        "You currently have no transaction records.": {
            "en": "You currently have no transaction records.",
            "ar": "ليس لديك أي معاملات جارية.",
            "ur": ""
        },
        "The discount code is not valid": {
            "en": "The discount code is not valid",
            "ar": "الكود غير صالح",
            "ur": ""
        },
        "Safely logging you out of your account. Please Wait...": {
            "en": "Safely logging you out of your account. Please Wait...",
            "ar": "جاري تسجيل خروجك، يرجى الانتظار...",
            "ur": ""
        }
    }
    return translations[text] ? translations[text][language] : text;
}
export default _T;