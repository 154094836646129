import React, { useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import _T from '../../locale/Home/translator';
import { GetBaseURL } from '../../common/getBaseURL';
import { useSearchParams } from 'react-router-dom';

function Contact({language}){
    const [ search, setSearch] = useSearchParams();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [apiLoading, setApiLoading] = useState(false);
    const [formResponse, setFormResponse] = useState(null);
    const validateSubmit = (event) => {
        event.preventDefault(); 
        const n = document.getElementById("name").value;
        const e = document.getElementById("email").value;
        const p = document.getElementById("phone").value;
        const s = document.getElementById("subject").value;
        const m = document.getElementById("message").value;
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
        let flag = true;
        if(n.replace(/\s/g,'').length < 3){
            setName( _T("Provide a valid name") );
            document.getElementById("name").value = "";
            flag = false;
        }
        if(!e.match(mailformat)){
            setEmail( _T("Provide a valid email address") );
            document.getElementById("email").value = "";
            flag = false;
        }
        // if(!p.match(phoneno)){
        if(p.replace(/\s/g,'').length < 8){
            setPhone( _T("Provide a valid phone number (+XXXXXXXXXX)") );
            document.getElementById("phone").value = "";
            flag = false;
        }
        if(s.replace(/\s/g,'').length < 5){
            setSubject( _T("Provide a valid subject line") );
            document.getElementById("subject").value = "";
            flag = false;
        }
        if(m.replace(/\s/g,'').length < 5){
            setMessage( _T("Provide your message") );
            document.getElementById("message").value = "";
            flag = false;
        }
        if(flag){
            setApiLoading(true)
            axios.post(process.env.REACT_APP_API_BASEURL+'contact-request', { name: n, email: e, phone: p, subject: s, message: m,}).then(function (response) {
                document.getElementById("contactForm").reset(); 
                setFormResponse(<p className='text-success text-center'>{ _T("Your message was sent successfully. We will get back to you shortly.") }</p>)
                setTimeout(() => {
                    setFormResponse(null)
                }, 6000);
            }).catch(function (error) {
                setApiLoading(false)
                document.getElementById("contactForm").reset(); 
                setFormResponse(<p className='text-danger text-center'>{ _T("Something went wrong. Please try again!") }</p>)
                setTimeout(() => {
                    setFormResponse(null)
                }, 6000);
            });
        }
    }

    return (
        <section id='contact'>
            <div className='wrapper contact-wrapper'>
                <div className="overlay"></div>
                <div className='section-left'>
                    <div className='form-container'>
                        <h2 className='secondary-heading'>{ _T("Contact Us!") }</h2>
                        <Box id="contactForm" onSubmit={validateSubmit} component="form" autoComplete="off" >
                            <div className='form-row'>
                                <TextField error={name.length > 0} id="name" label={ _T("Your Name") } helperText={name} onChange={()=>{setName("")}} />
                                <TextField error={email.length > 0} id="email" type={"email"} label={ _T("Your Email Address") } helperText={email} onChange={()=>{setEmail("")}} />
                            </div>
                            <div className='form-row'>
                                <TextField error={phone.length > 0} id="phone" type={"tel"} label={ _T("Your Phone") } helperText={phone} onChange={()=>{setPhone("")}} />
                                <TextField error={subject.length > 0} id="subject" label={ _T("Your Subject") } helperText={subject} onChange={()=>{setSubject("")}} />
                            </div>
                            <div className='form-row message-row'>
                                <TextField error={message.length > 0} id="message" label={ _T("Your Message") } multiline rows={4} fullWidth helperText={message} onChange={()=>{setMessage("")}} />
                            </div>
                            {formResponse}
                            <div className='form-footer'>
                                <button type='submit' disabled={apiLoading} className='btn btn-primary'>{ _T("Submit") }&nbsp;&nbsp;<i className="ri-send-plane-fill"></i></button>
                            </div>
                        </Box>
                    </div>
                </div>
                <div className='section-right'>
                    <h2 className='secondary-heading'>{ _T("Our Social Accounts") }</h2>
                    <div className='social-links'>
                        <a className='social-single' rel='noreferrer' href="https://www.facebook.com/tawkeelapp1/" target={"_blank"}>
                            <i className="ri-facebook-fill"></i>
                            <div className='social-text'>
                                <h5>{ _T("Facebook") }</h5>
                                <h6 className='escape'>tawkeelapp1</h6>
                            </div>
                        </a>
                        <a className='social-single' rel='noreferrer' href="https://www.instagram.com/tawkeelapp/" target={"_blank"}>
                            <i className="ri-instagram-fill"></i>
                            <div className='social-text'>
                                <h5>{ _T("Instagram") }</h5>
                                <h6 className='escape'>@tawkeelapp</h6>
                            </div>
                        </a>
                        <a className='social-single' rel='noreferrer' href="https://twitter.com/TawkeelApp" target={"_blank"}>
                            <i className="ri-twitter-x-fill"></i>
                            <div className='social-text'>
                                <h5>{ _T("X") }</h5>
                                <h6 className='escape'>@TawkeelApp</h6>
                            </div>
                        </a>
                        <a className='social-single' rel='noreferrer' href="https://www.linkedin.com/company/tawkeel-app/about/" target={"_blank"}>
                            <i className="ri-linkedin-fill"></i>
                            <div className='social-text'>
                                <h5>{ _T("LinkedIn") }</h5>
                                <h6 className='escape'>Tawkeel App</h6>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;