import Navbar from '../../components/NavbarInner'
import Footer from '../../components/FooterInner'
import { languages } from '../../common/languages'
import '../../assets/css/faq.css';
import Hero from './Hero';
import { useEffect } from 'react';
import { RP } from '../../components/FacebookPixel';
import '../../components/TikTokPixel.js';
import _TN from '../../locale/News/translator.js';
import "../../assets/css/home.css";
import defaultImage from '../../assets/images/default-news.jpg'
import post01 from '../../assets/images/news/hajj-umrah-expo-2025.jpg'

function News({ language }) {
    useEffect(() => {
        RP.pageView();
    }, [])

    window.scrollTo({ top: 0 });
    let direction = languages[language].direction
    if (direction === "RTL") {
        document.getElementsByTagName("body")[0].classList.add("RTL");
    } else {
        document.getElementsByTagName("body")[0].classList.remove("RTL");
    }


    return (
        <>
            <Navbar language={languages[language]} />
            <Hero language={languages[language]} />
            <section id='news'>
                <div className='wrapper news-wrapper'>
                    <div className='section-top'>
                        <div className='news-card-list'>
                            <div className='news-card'>
                                <img className='news-image' alt='news-thumbnail' src={post01} onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} />
                                <a href='https://hajjconfex.com/' target={"_blank"} rel="noreferrer" className='news-title'>{_TN('Tawkeel Company is getting ready to Participate in the Hajj Conference and Exhibition 2025')}</a>
                                <p className='news-description'>{_TN('As a testament to its commitment to delivering top-notch services, Tawkeel is preparing to showcase its latest offerings and innovative solutions designed to enhance the Umrah on behalf of others service')}</p>
                                <a href='https://hajjconfex.com/' target={"_blank"} rel="noreferrer" className='news-link'>{_TN('Read More')}</a>
                            </div>
                            <div className='news-card'>
                                <img className='news-image' alt='news-thumbnail' src='https://malaysiagazette.com/wp-content/uploads/2024/01/umrah.jpg' onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} />
                                <a href='https://malaysiagazette.com/2024/01/10/mata-aplikasi-tawkeel-jalin-kerjasama-elak-penipuan-badal-umrah/' target={"_blank"} rel="noreferrer" className='news-title'>{_TN('MATA and Tawkeel collaborate to avoid Umrah Al Badal fraud')}</a>
                                <p className='news-description'>{_TN('KUALA LUMPUR - The Malaysian Association of Travel Agencies (MATA) has partnered with Tawkeel, a leading Saudi technology company, to leverage innovative technology solutions to prevent fraud by Umrah Al Badal operators.')}</p>
                                <a href='https://malaysiagazette.com/2024/01/10/mata-aplikasi-tawkeel-jalin-kerjasama-elak-penipuan-badal-umrah/' target={"_blank"} rel="noreferrer" className='news-link'>{_TN('Read More')}</a>
                            </div>
                            <div className='news-card'>
                                <img className='news-image' alt='news-thumbnail' src='https://media.assettype.com/sabq%2F2023-01%2F73f498cf-38c1-4b2b-ade3-82ba885408d9%2Fsabq_2023_01_d9648695_cc65_4321_ba17_64cbce26ce75_cats_30.avif?w=1200&auto=format%2Ccompress&fit=max' onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} />
                                <a href='https://sabq.org/saudia/p14ijrl0lf' target={"_blank"} rel="noreferrer" className='news-title'>{_TN('Tawkeel introduces its services at the Hajj and Umrah Expo 2023')}</a>
                                <p className='news-description'>{_TN('The Tawkeel application for Hajj and Umrah showcased its services provided to facilitate the performance of rituals for those unable to do so and targets Muslims around the world.')}</p>
                                <a href='https://sabq.org/saudia/p14ijrl0lf' target={"_blank"} rel="noreferrer" className='news-link'>{_TN('Read More')}</a>
                            </div>
                            <div className='news-card'>
                                <img className='news-image' alt='news-thumbnail' src='https://thenational-the-national-prod.cdn.arcpublishing.com/resizer/v2/TADZDRFKCRF5JPE474KLI6FA74?smart=true&auth=e10b4e199d2d2b6f187c8db602b8798e8919d01dcadd5184ec4464101e6ad3a0&width=1000&height=562' onError={(e) => { e.target.onerror = null; e.target.src = defaultImage }} />
                                <a href='https://www.thenationalnews.com/gulf-news/2023/01/11/hajj-expo-2023-all-you-need-to-know-about-the-pilgrimage/' target={"_blank"} rel="noreferrer" className='news-title'>{_TN('Hajj 2023: How technology is transforming pilgrimage')}</a>
                                <p className='news-description'>{_TN('A Saudi start-up is showing off its Tawkeel application that helps Muslims around the world to delegate rituals to be performed by others on their behalf.')}</p>
                                <a href='https://www.thenationalnews.com/gulf-news/2023/01/11/hajj-expo-2023-all-you-need-to-know-about-the-pilgrimage/' target={"_blank"} rel="noreferrer" className='news-link'>{_TN('Read More')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer language={languages[language]} />
        </>
    )
}

export default News;