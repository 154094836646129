import '../assets/css/--component-modal.css';
function Modal(props) {
    const {isOpen,onClose} = props;
    return(
        isOpen ?
        <div className={`modal ${props.className ? props.className : ""}`}>
            <div className="modal-overlay" onClick={onClose ? onClose : null}></div>
            <div className={`modal-container ${props.modalSize ? "modal-container-"+props.modalSize : ""}`}>
                <div className="modal-content">
                    {
                        props.title || onClose ?
                        <div className="modal-header">
                            { props.title ? <h2 className="modal-title">{props.title}</h2> : null }
                            { onClose ? <button className={`modal-close ${props.title ? "" : "single"} mb-2`} type='button' onClick={onClose}><i className="ri-close-line"></i></button> : null }
                        </div> : null
                    }
                    <div className="modal-body">
                        { props.children }
                    </div>
                </div>
            </div>
        </div> : null
    )       
}

export default Modal;