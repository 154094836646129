import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import 'remixicon/fonts/remixicon.css'
import "flatpickr/dist/themes/material_green.css"

ReactDOM.render(
    <App />,
  document.getElementById('root')
);