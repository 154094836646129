import React from "react";
import { Row, Col } from "reactstrap";
import SeekerAgencyAdditionalStatistics from "./SeekerAgencyAdditionalStatistics";
import SeekerAgencyStatistics from "./SeekerAgencyStatistics";

function ReportAnalysis() {
  return (
    <div className="page-content">
      <Row>
        <Col xl={12}>
          <SeekerAgencyStatistics />
        </Col>
        <Col xl={12}>
          <SeekerAgencyAdditionalStatistics />
        </Col>
      </Row>
    </div>
  );
}
export default ReportAnalysis