import React, { useEffect, useRef, useState } from "react";
import OrderManagement from "./OrderManagement";
import PaymentPage from "./PaymentPage";
import PurchaseCode from "./PurchaseCode";
import Invoices from "./Invoices";
import ReportAnalysis from "./ReportAnalysis";
import MyProfile from "./MyProfile";
import "../../assets/css/agent-agency.css";
import DashboardLogo from "../../assets/images/dashboardLogo.png"
import { Link, useNavigate, useLocation, NavLink } from 'react-router-dom';
import _T from '../../locale/AgencyPortal/translator';
import { languages } from '../../common/languages'

const AgencyDashboard = ({ language }) => {

    let direction = languages[language].direction
    if (direction === "RTL") {
        document.getElementsByTagName("body")[0].classList.add("RTL");
    } else {
        document.getElementsByTagName("body")[0].classList.remove("RTL");
    }

    const [activeTab, setActiveTab] = useState("OrderManagement");
    const [user, setUser] = useState({});
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const history = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let userObject = localStorage.getItem("user");
        try {
            userObject = JSON.parse(userObject);
            setUser(userObject);
        } catch (error) {
            return false;
        }
    }, [])
    useEffect(() => {
        const currentPath = location.pathname;
        if (currentPath === "/order-management") {
            setActiveTab("OrderManagement")
        }
        else if (currentPath === "/payments") {
            setActiveTab("PaymentPage")
        }
        else if (currentPath === "/purchase-code") {
            setActiveTab("PurchaseCode")
        }
        else if (currentPath === "/invoices") {
            setActiveTab("Invoices")
        }
        else if (currentPath === "/report-analysis") {
            setActiveTab("ReportAnalysis")
        }
        else if (currentPath === "/agency-profile") {
            setActiveTab("AgencyProfile")
        }
        else {
            setActiveTab("OrderManagement")
        }
    }, [])

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    function toggleDropdownn() {
        setDropDownOpen(!dropDownOpen);
    }
    const handleOptionClick = () => {
        setDropDownOpen(false);
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropDownOpen(false);
        }
    };

    function switch_language(e, language) {
        e.preventDefault()
        let url = window.location.href
        if (language === "en") {
            url = url.replace('/en/', '/')
            url = url.replace('/en', '/')
            localStorage.setItem("lang", "ar")
        }
        else {
            var base_url = window.location.origin;
            url = base_url + '/en/' + url.replace(base_url + '/', '');
            localStorage.setItem("lang", "en")
        }
        window.location.href = url;
    }

    const renderTabContent = () => {
        switch (activeTab) {
            case "OrderManagement":
                return <OrderManagement language={language} />;
            case "PaymentPage":
                return <PaymentPage language={language} />;
            case "PurchaseCode":
                return <PurchaseCode language={language} />;
            case "Invoices":
                return <Invoices language={language} />;
            case "ReportAnalysis":
                return <ReportAnalysis language={language} />;
            case "AgencyProfile":
                return <MyProfile language={language} />;
            default:
                return null;
        }
    };

    return (
        <>
            <div className="dashboard-header" >
                <div className="dashboard-header-wrapper pl-2 pr-2">
                    <div className="section-left">
                        <img src={DashboardLogo} alt="" />
                    </div>
                    <div className="section-right d-flex align-items-center">
                        <a href="https://wa.me/+9668002442203" target="_blank" className={`btn btn-support ${language === "en" ? 'mr-3' : 'ml-3'}`}><i className="ri-customer-service-2-fill"></i> <div>&nbsp;</div><div>&nbsp;</div>{_T("Support")}</a>
                        <div className="agency-badge umrah-dropdown" ref={dropdownRef} onClick={toggleDropdownn} style={{ cursor: "pointer" }}>
                            <div className="agency-icon">
                                <i className="ri-user-line"></i>
                            </div>
                            <span className="agency-name">{user && user.name ? user.name : ""}</span>
                            {dropDownOpen && (
                                <>
                                    <div className="umrah-dropdown-content" style={{ top: "38px", left: "0px" }}>
                                        <button onClick={(e) => { switch_language(e, language) }}>
                                            <i className="ri-global-line mr-1 fs-22"></i>
                                            {language === "en" ? 'العربي' : 'English'}
                                        </button>
                                        <button onClick={() => { handleOptionClick(); history('/logout') }}>{_T("Logout")}</button>
                                    </div>
                                    {/* <div className='btn-group'>
                                        <
                                    </div> */}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard-wrapper">
                {/* Tabs Header */}
                <div className="tabs">
                    {/* <NavLink className="button" onClick={() => setActiveTab("OrderManagement")}>Order Management</NavLink>
                    <NavLink className="button" onClick={() => setActiveTab("PaymentPage")}>Payment Page</NavLink>
                    <NavLink className="button" onClick={() => setActiveTab("PurchaseCode")}>Purchase Code</NavLink>
                    <NavLink className="button" onClick={() => setActiveTab("Invoices")}>Invoices</NavLink>
                    <NavLink className="button" onClick={() => setActiveTab("ReportAnalysis")}>Report and Analysis</NavLink> */}
                    {/* <NavLink className="button" to={'/'}>Report and Analysis</NavLink> */}
                    <NavLink className="button" to={language === "en" ? '/en/order-management' : '/order-management'} onClick={() => setActiveTab("OrderManagement")}>{_T("Order Management")}</NavLink>
                    <NavLink className="button" to={language === "en" ? '/en/payments' : '/payments'} onClick={() => setActiveTab("PaymentPage")}>{_T("Payment Page")}</NavLink>
                    <NavLink className="button" to={language === "en" ? '/en/purchase-code' : '/purchase-code'} onClick={() => setActiveTab("PurchaseCode")}>{_T("Purchased Codes")}</NavLink>
                    <NavLink className="button" to={language === "en" ? '/en/invoices' : '/invoices'} onClick={() => setActiveTab("Invoices")}>{_T("Invoices")}</NavLink>
                    <NavLink className="button" to={language === "en" ? '/en/report-analysis' : '/report-analysis'} onClick={() => setActiveTab("ReportAnalysis")}>{_T("Report and Analysis")}</NavLink>
                    <NavLink className="button" to={language === "en" ? '/en/agency-profile' : '/agency-profile'} onClick={() => setActiveTab("AgencyProfile")}>{_T("My Profile")}</NavLink>
                </div>

                {/* Render Active Tab Content */}
                <div className="tab-content">
                    {renderTabContent()}
                </div>
            </div>
        </>
    );
};

export default AgencyDashboard;